import React, { useEffect, useState } from "react";
import ItemCompany from "./ItemCompany";
import { request } from "../../utils/request";
import { useNavigate } from "react-router-dom";
import isIdApp from "../../utils/isIdApp";

export default function CompanyApp() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const handleGetApp = async () => {
		try {
			const response = await request.get(`api/app`, {
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
				params: {
					t: 1,
					page: 1,
					limit: 30,
					q: {},
				},
			});
			setData(response.data);
		} catch (error) {
			console.log(error.message);
			navigate("/sign-in");
		}
	};

	useEffect(() => {
		handleGetApp();
	}, []);
	return (
		<React.Fragment>
			{isIdApp() && (
				<div className="flex items-center font-medium text-base text-[#09395e] py-3">
					Công ty hiện tại: {localStorage.getItem("name_app")}
				</div>
			)}
			<div className="flex justify-start items-center flex-wrap">
				{data.map((item, index) => (
					<ItemCompany item={item} key={index} />
				))}
			</div>
		</React.Fragment>
	);
}
