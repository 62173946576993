import { Button, Checkbox, Dropdown } from "antd";
import React from "react";
import { AiFillSetting } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const items = [
	{
		key: "1",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Sao chép
			</div>
		),
	},
	{
		key: "2",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Sửa
			</div>
		),
	},
	{
		key: "3",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Xoá
			</div>
		),
	},
	{
		key: "4",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Lịch sử
			</div>
		),
	},
	{
		key: "5",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Gộp...
			</div>
		),
	},
];

export default function ItemUserGroup({ key, data }) {
	const navigate = useNavigate();
	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};
	return (
		<div
			key={key}
			className="border flex justify-between items-center gap-2 cursor-pointer py-2 hover:bg-slate-100"
		>
			<div className="flex justify-start items-center w-[100px]">
				<div className="flex justify-center w-full">
					<Checkbox onChange={onChange}></Checkbox>
				</div>
				<div className="flex justify-center w-full">
					<Dropdown
						menu={{
							items,
						}}
						placement="bottomLeft"
					>
						<Button
							className="mx-[8px!important] border-none flex !text-cyan-900 justify-center items-center hover:bg-slate-200"
							shape="circle"
							size="small"
						>
							<AiFillSetting size={18} />
						</Button>
					</Dropdown>
				</div>
			</div>
			<div className="w-[calc(100%-100px)] flex justify-between items-center px-3">
				<div className="flex flex-col items-start gap-1">
					<span className="text-[#09395e] text-sm font-semibold">
						{data?.group_name}
					</span>
					<p className="text-[#09395e] text-xs">{data?._id}</p>
				</div>
				<div className="flex gap-2">
					<Button
						type="primary"
						htmlType="submit"
						className="!text-xs h-fit bg-white hover:!bg-slate-200 hover:!text-[#326b97] text-[#326b97] !font-medium !border-none !rounded"
						onClick={() => {
							navigate("/participant?group_id=" + data?._id);
						}}
					>
						Danh sách người dùng
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						className="!text-xs h-fit bg-[#326b97] hover:!bg-[#09395e] hover:!text-white text-white !font-medium !border-none !rounded"
					>
						Phân quyền
					</Button>
				</div>
			</div>
		</div>
	);
}
