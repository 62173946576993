import { Button, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineLine, AiOutlinePlus } from "react-icons/ai";
import { GrAdd } from "react-icons/gr";
import { request } from "../../utils/request";
import PopoverModel from "../components/modal/popoverModel";
import TableManager from "../components/table/tableManager";
import FormManagerMenu from "./formManagerMenu";

export default function ManagerMenu() {
	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const [dataToolbar, setDataToolbar] = useState([]);

	const handleGetDataMenu = async (q) => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/webpages`,
				{
					params: {
						q: { ...q },
					},
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			return response?.data;
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};

	const handleDeleteWebPages = async (id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/webpages/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
			handleGetDataAllMenu();
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
			//throw error;
		}
	};

	const handleCreateWebPages = async (values) => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/webpages`,
				values,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Tạo thành công",
			});
			handleGetDataAllMenu();
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Tạo Thất bại",
			});
			//throw error;
		}
	};

	const handleEditWebPages = async (values, id) => {
		try {
			await request.put(
				`api/${localStorage.getItem("id_app")}/webpages/${id}`,
				values,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Thay đổi thành công",
			});
			handleGetDataAllMenu();
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Thay đổi thất bại",
			});
			//throw error;
		}
	};

	useEffect(() => {
		handleGetDataAllMenu();
	}, []);

	async function handleGetDataAllMenu() {
		setLoading(true);
		try {
			const data1 = await handleGetDataMenu({ parent_page: "null" });
			const data = await Promise.all(
				data1.map(async (item) => {
					const data_ = [];
					const data2 = await handleGetDataMenu({
						parent_page: item.title,
					});
					await Promise.all(
						data2.map(async (childItem) => {
							data_.push({ ...childItem });
							console.log(childItem);
						})
					);
					return {
						...item,
						submenu: [...data_],
					};
				})
			);
			setDataToolbar(data);
			console.log(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
			//throw error;
		}
	}

	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			render: (title, record) => (
				<div className="flex flex-col field_title">
					<span>{title}</span>
					<div className="show_group_button">
						<span>
							<a
								href={`${localStorage.getItem(
									localStorage.getItem("id_app")
								)}${record?.path}`}
								target="_blank"
								rel="noreferrer"
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#326b97]"
							>
								View
							</a>
							<PopoverModel
								content={
									<FormManagerMenu
										dataToolbar={dataToolbar}
										handleManegerWebPages={
											handleEditWebPages
										}
										dataMenu={record}
									/>
								}
								title={null}
								trigger="click"
								arrow={true}
								placement="right"
							>
								<div className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]">
									Edit
								</div>
							</PopoverModel>
							<div
								onClick={() =>
									handleDeleteWebPages(record?._id)
								}
								className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]"
							>
								Delete
							</div>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Order",
			dataIndex: "stt",
			key: "stt",
		},
		// {
		// 	title: "Parent",
		// 	dataIndex: "parent_page",
		// 	key: "parent_page",
		// 	render: (parent_page) => {
		// 		if (parent_page !== "null") {
		// 			return parent_page;
		// 		} else {
		// 			return "";
		// 		}
		// 	},
		// },
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => {
				if (status) {
					return <Tag color="green">Active</Tag>;
				} else {
					return <Tag color="red">Disabled</Tag>;
				}
			},
		},
		{
			title: "Date",
			dataIndex: "date_created",
			key: "date_created",
			render: (date_created) => {
				return moment(date_created).format("YYYY-MM-DD HH:mm:ss");
			},
		},
	];

	return (
		<div>
			{contextHolder}
			<div className="h-12 flex justify-between items-center my-2">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Manager Menu
				</div>
				<div className="flex justify-end gap-1 items-center">
					<PopoverModel
						content={
							<FormManagerMenu
								dataToolbar={dataToolbar}
								handleManegerWebPages={handleCreateWebPages}
							/>
						}
						title={null}
						trigger="click"
						arrow={true}
						placement="leftTop"
					>
						<Button className="!border-[#09395e] hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1">
							<GrAdd /> Add
						</Button>
					</PopoverModel>
				</div>
			</div>
			<TableManager
				data={dataToolbar}
				columns={columns}
				loading={loading}
				scroll={{ y: "calc(100vh - 232px)" }}
				expandable={{
					expandedRowRender: (record) => {
						if (record?.submenu && record.submenu.length > 0) {
							return (
								<TableManager
									data={record.submenu}
									columns={columns}
									scroll={{
										x: 1100,
										y: "calc(100vh - 332px)",
									}}
									pagination={false}
									className={"bg-emerald-200"}
								/>
							);
						}
						return null; // Trả về null nếu không có dữ liệu submenu
					},
				}}
				expandIcon={({ expanded, onExpand, record }) => {
					if (record?.submenu && record.submenu.length > 0) {
						return (
							<span
								onClick={(e) => onExpand(record, e)}
								className="flex justify-center items-center cursor-pointer"
							>
								<div className="p-[1px] border rounded hover:text-[#326b97] hover:border-[#326b97]">
									{expanded ? (
										<AiOutlineLine />
									) : (
										<AiOutlinePlus />
									)}
								</div>
							</span>
						);
					}
					return null;
				}}
				className="border-b-[1px] border-slate-400"
			/>
		</div>
	);
}
