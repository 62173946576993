import { Button, Dropdown, Popconfirm, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { GrAdd } from "react-icons/gr";
import { request } from "../../../utils/request";
import TableManager from "../../components/table/tableManager";
import UploadFile from "./component/uploadFile";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default function MediaLibrary() {
	const [dataFile, setDataFile] = useState({});
	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const [isChooseImage, setIsChooseImage] = useState(false);
	const [typeImage, setTypeImage] = useState("");

	const columns = [
		{
			title: "Name",
			key: "exfields",
			dataIndex: "exfields",
			width: 200,
			render: (_, record) => (
				<div className="flex flex-col field_title">
					<span>{record?.exfields?.name}</span>
					<div className="show_group_button">
						<span>
							<div
								onClick={(e) => {
									e.stopPropagation();
									setTypeImage("edit");
									setIsChooseImage(true);
									setDataFile({
										_id: record._id,
										link: record.link,
										type: record.type,
										exfields: {
											name: record.exfields.name,
										},
									});
								}}
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div>
							<Popconfirm
								title="Delete User Files?"
								description={`Are you sure to delete ${record?.exfields?.name} ?`}
								onConfirm={(e) => {
									e.stopPropagation();
									handleDeleteUserFiles(record?._id);
								}}
								onCancel={(e) => {
									e.stopPropagation();
								}}
								okText={
									<p className="text-[#3a78d4] hover:text-white w-full h-full">
										Yes
									</p>
								}
								cancelText="No"
								icon={
									<QuestionCircleOutlined
										style={{
											color: "red",
										}}
									/>
								}
							>
								<div
									onClick={(e) => {
										e.stopPropagation();
									}}
									className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]"
								>
									Delete
								</div>
							</Popconfirm>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Content",
			dataIndex: "link",
			key: "link",
			width: 200,
			render: (link) => (
				<div className="w-1/2 mx-auto">
					<a
						title={link}
						href={link}
						target="_blank"
						rel="noreferrer"
					>
						<img src={link} alt="" />
					</a>
				</div>
			),
		},

		{
			title: "Type",
			key: "type",
			dataIndex: "type",
		},
		{
			title: "Author",
			dataIndex: "name_user_created",
		},
		{
			title: "Date",
			dataIndex: "date_created",
			width: 200,
			render: (date_created) => {
				return (
					<div className="flex flex-col">
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];

	const handleDeleteUserFiles = async (id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/userfiles/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			messageApi.open({
				type: "success",
				content: "Xoá thành công",
			});
			handleGetUserFiles();
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
			console.log(error);
		}
	};

	const [data, setData] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const handleGetUserFiles = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
					params: {
						t: 1,
						page: 1,
						limit: 500,
					},
				}
			);
			setData(response.data);
			setLoading(false);
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		handleGetUserFiles();
	}, []);

	return (
		<div>
			{contextHolder}
			<div className="h-12 flex justify-between items-center my-2">
				<div className="w-1/2 flex items-center font-bold text-xl text-[#09395e] gap-5">
					Media Library
				</div>
				<div className="flex justify-end items-center box_menu_post">
					<Button
						onClick={() => {
							setIsChooseImage(true);
							setTypeImage("add");
							setDataFile({
								link: "",
								type: "image",
								exfields: {
									name: "image",
								},
							});
						}}
						className="!border-[#09395e] hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1"
					>
						<GrAdd /> Add
					</Button>
				</div>
			</div>
			<TableManager
				data={data}
				columns={columns}
				loading={loading}
				scroll={{ y: "calc(100vh - 232px)" }}
				rowSelectionCus={{
					selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						console.log(selectedRowKeys, selectedRows);
						setSelectedRowKeys(selectedRowKeys);
					},
				}}
				onRow={(record, rowIndex) => {
					return {
						onDoubleClick: () => {
							setTypeImage("edit");
							setIsChooseImage(true);
							setDataFile({
								_id: record._id,
								link: record.link,
								type: record.type,
								exfields: {
									name: record.exfields.name,
								},
							});
						},
					};
				}}
				className="border-b-[1px] border-slate-400"
			/>
			<UploadFile
				type={typeImage}
				data={dataFile}
				isChooseImage={isChooseImage}
				setIsChooseImage={setIsChooseImage}
				messageApi={messageApi}
				setLoading={setLoading}
				handleGetUserFiles={handleGetUserFiles}
			/>
		</div>
	);
}
