import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PopoverModel from "../../../components/modal/popoverModel";
import UploadImage from "../../components/uploadImage";
import "../section.scss";
import {
	TlIconCafe,
	TlIconCanteen,
	TlIconFastFood,
	TlIconKiosk,
	TlIconMilktea,
	TlIconRestaurant,
} from "../utils/icons";

export default function SectionContent1({ data, handleUpdateData }) {
	const [dataSection, setDataSection] = useState(data || {});

	//upload image
	const [isChooseImage, setIsChooseImage] = useState(false);
	const handleChooseImage = () => {
		setIsChooseImage(true);
	};
	const handleCancelChooseImage = () => {
		setIsChooseImage(false);
	};

	useEffect(() => {
		handleUpdateData(dataSection);
	}, [dataSection]);

	return (
		<section className="section_service_1">
			<div className="box_service_1 flex flex-col items-center justify-center gap-5">
				<PopoverModel
					content={
						<div className="flex flex-col gap-3">
							<Input
								placeholder="Name"
								defaultValue={dataSection?.button?.name}
								onChange={(e) =>
									setDataSection({
										...dataSection,
										button: {
											...dataSection?.button,
											name: e.target.value,
										},
									})
								}
								className="w-[200px]"
							/>
							<Input
								placeholder="Url"
								defaultValue={dataSection?.button?.url}
								onChange={(e) =>
									setDataSection({
										...dataSection,
										button: {
											...dataSection?.button,
											url: e.target.value,
										},
									})
								}
								className="w-[200px]"
							/>
						</div>
					}
					title={
						<span className="text-xs font-normal">Thay đổi</span>
					}
					trigger="click"
					arrow={true}
				>
					<Button className="btn-grad">
						{dataSection.button?.name || "Button"}
					</Button>
				</PopoverModel>
				<div className="flex text-center">
					<PopoverModel
						content={
							<Input.TextArea
								placeholder="nội dung"
								defaultValue={dataSection.title}
								onChange={(e) =>
									setDataSection({
										...dataSection,
										title: e.target.value,
									})
								}
								rows={4}
								className="w-[300px]"
							/>
						}
						title={
							<span className="text-xs font-normal">
								Thay đổi
							</span>
						}
						trigger="click"
						arrow={true}
					>
						<h2 className="!text-3xl !font-semibold text-[#09395e] hover:bg-slate-100 w-4/5 mx-auto">
							{dataSection.title}
						</h2>
					</PopoverModel>
				</div>
				<div className="border-t-[7px] w-[20vh] my-4"></div>
				<div className="box_service_1_content flex gap-3">
					<div
						onClick={handleChooseImage}
						className="box_service_1_content_left w-1/2 mx-auto hover:bg-slate-200 cursor-pointer"
						data-aos="fade-up"
					>
						<img
							src={
								dataSection.image ||
								"https://api.goodapp.vn/getfile/files/6498312796f391dad61d71d7_1697377814844_city.svg"
							}
							alt="ImageAdvise1"
							className="w-full rounded-xl max-h-[330px] object-contain"
						/>
					</div>
					<UploadImage
						setData={setDataSection}
						data={dataSection}
						isChooseImage={isChooseImage}
						handleCancelChooseImage={handleCancelChooseImage}
					/>
					<div className="box_service_1_content_right w-1/2 p-3 flex flex-col gap-5">
						<div>
							<PopoverModel
								content={
									<Input.TextArea
										placeholder="nội dung"
										defaultValue={dataSection.description}
										onChange={(e) =>
											setDataSection({
												...dataSection,
												description: e.target.value,
											})
										}
										rows={4}
										className="w-[300px]"
									/>
								}
								title={
									<span className="text-xs font-normal">
										Thay đổi
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<h3 className="!text-lg !font-normal text-left hover:bg-slate-100">
									{dataSection.description}
								</h3>
							</PopoverModel>
						</div>
						<div className="flex items-center h-[-webkit-fill-available] mx-auto">
							<div className="flex justify-between items-center flex-wrap gap-3">
								<div className="item_advise1 flex flex-col items-center gap-2 w-[calc(100%/3-10px)] cursor-pointer">
									<span>
										<img
											src={TlIconRestaurant}
											alt="IconRestaurant"
											className="!max-w-[45px]"
										/>
									</span>
									<h4 className="!text-[18px] !font-normal text-center min-h-[48px]">
										{" "}
										Nhà hàng
									</h4>
								</div>
								<div className="item_advise1 flex flex-col items-center gap-2 w-[calc(100%/3-10px)] cursor-pointer">
									<span>
										<img
											src={TlIconCafe}
											alt="IconCafe"
											className="!max-w-[45px]"
										/>
									</span>
									<h4 className="!text-[18px] !font-normal text-center min-h-[48px]">
										{" "}
										Coffee
									</h4>
								</div>
								<div className="item_advise1 flex flex-col items-center gap-2 w-[calc(100%/3-10px)] cursor-pointer">
									<span>
										<img
											src={TlIconMilktea}
											alt="IconMilktea"
											className="!max-w-[45px]"
										/>
									</span>
									<h4 className="!text-[18px] !font-normal text-center min-h-[48px]">
										{" "}
										Trà sữa
									</h4>
								</div>
								<div className="item_advise1 flex flex-col items-center gap-2 w-[calc(100%/3-10px)] cursor-pointer">
									<span>
										<img
											src={TlIconFastFood}
											alt="IconFastFood"
											className="!max-w-[45px]"
										/>
									</span>
									<h4 className="!text-[18px] !font-normal text-center min-h-[48px]">
										{" "}
										Fast-food
									</h4>
								</div>
								<div className="item_advise1 flex flex-col items-center gap-2 w-[calc(100%/3-10px)] cursor-pointer">
									<span>
										<img
											src={TlIconKiosk}
											alt="IconKiosk"
											className="!max-w-[45px]"
										/>
									</span>
									<h4 className="!text-[18px] !font-normal text-center min-h-[48px]">
										{" "}
										Quán nhỏ - Kiosk
									</h4>
								</div>
								<div className="item_advise1 flex flex-col items-center gap-2 w-[calc(100%/3-10px)] cursor-pointer">
									<span>
										<img
											src={TlIconCanteen}
											alt="IconCanteen"
											className="!max-w-[45px]"
										/>
									</span>
									<h4 className="!text-[18px] !font-normal text-center min-h-[48px]">
										{" "}
										Canteen - Foodcourt
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
