import { Button, Input, Popconfirm, Tabs, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GrAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { request } from "../../../utils/request";
import PopupModel from "../../components/modal/popupModel";
import TableManager from "../../components/table/tableManager";
import ModalCategory from "./Modal/ModalCategory";
import PopoverModel from "../../components/modal/popoverModel";
const { Search } = Input;

export default function Category() {
	const [dataProduct, setDataProduct] = useState([]);

	const handleGetData = async (api, q) => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/${api}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
					params: {
						q: { ...q },
					},
				}
			);
			return response?.data;
		} catch (error) {
			console.log(error);
		}
	};
	const handleSaveData = async (data, api = "group") => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/${api}`,
				{ ...data, group_type: "news" },
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			const newData = await handleGetData(api, {
				status: true,
			});
			setDataProduct(newData);
			messageApi.open({
				type: "success",
				content: "Tạo thành công",
			});
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Tạo thất bại",
			});
		}
	};
	const handleEditData = async (data, api = "group") => {
		try {
			await request.put(
				`api/${localStorage.getItem("id_app")}/${api}/${data._id}`,
				data,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			const newData = await handleGetData(api, {
				status: true,
			});
			setDataProduct(newData);
			messageApi.open({
				type: "success",
				content: "Sửa thành công",
			});
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Sửa thất bại",
			});
		}
	};
	const handleDeleteData = async (api, id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/${api}/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			const newData = await handleGetData(api, {
				status: true,
			});
			setDataProduct(newData);
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
		}
	};

	const handleGetDataAll = async () => {
		try {
			const newDataProduct = await handleGetData("group", {
				status: true,
				group_type: "news",
			});
			setDataProduct(newDataProduct);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		handleGetDataAll();
	}, []);

	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const columns = [
		{
			title: "Loại bài viết",
			dataIndex: "group_name",
			// fixed: "left",
			render: (ten_vt, record) => (
				<div className="flex flex-col field_title">
					<span>{ten_vt}</span>
					<div className="show_group_button">
						<span>
							<PopoverModel
								content={
									<ModalCategory
										data={record}
										handleManegerCategoryNews={
											handleEditData
										}
									/>
								}
								title={null}
								trigger="click"
								arrow={true}
								placement="right"
							>
								<div className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]">
									Edit
								</div>
							</PopoverModel>
							<Popconfirm
								title={`Xoá ${record?.group_name}`}
								onConfirm={() => {
									handleDeleteData("group", record?._id);
								}}
								onCancel={() => {
									return false;
								}}
								okText="Yes"
								cancelText="No"
							>
								<div className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]">
									Delete
								</div>
							</Popconfirm>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Trạng thái",
			key: "status",
			dataIndex: "status",
			render: (status, record) => {
				return (
					<>
						{status ? (
							<Tag color={"green"} key={status}>
								Sử dụng
							</Tag>
						) : (
							<Tag color={"red"} key={status}>
								Không sử dụng
							</Tag>
						)}
					</>
				);
			},
		},
		{
			title: "Author",
			dataIndex: "name_user_created",
		},
		{
			title: "Date",
			dataIndex: "date_created",
			width: 200,
			render: (date_created, record) => {
				return (
					<div className="flex flex-col">
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];

	const navigate = useNavigate();
	const onSearch = (value, _e, info) => console.log(info?.source, value);
	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	return (
		<React.Fragment>
			{contextHolder}
			<div className="h-12 flex justify-between items-center pt-4">
				<div className="w-1/2 flex items-center font-bold text-xl text-[#09395e] gap-5">
					Loại bài viết
				</div>
				<div className="flex justify-end gap-5 items-center box_menu_post">
					<PopoverModel
						content={
							<ModalCategory
								handleManegerCategoryNews={handleSaveData}
							/>
						}
						title={null}
						trigger="click"
						arrow={true}
						placement="leftTop"
					>
						<Button className="!border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3">
							<GrAdd /> Thêm mới
						</Button>
					</PopoverModel>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex justify-between items-center pt-4">
					<div>
						<Search
							placeholder="Từ khoá ..."
							allowClear
							onSearch={onSearch}
							style={{
								width: 300,
							}}
						/>
					</div>
				</div>
				<div className="box_content">
					<TableManager
						data={dataProduct}
						columns={columns}
						loading={loading}
						scroll={{ y: "calc(100vh - 332px)" }}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}
