import { Button, Checkbox, Form, Input } from "antd";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LogoSignIn } from "../../utils/images";
import { request } from "../../utils/request";
import "./signIn.scss";

export default function SignIn() {
	const formRef = useRef(null);
	const navigate = useNavigate();
	const onFinish = (values) => {
		handleLogin(values);
		formRef.current?.resetFields();
	};
	const handleLogin = async (values) => {
		try {
			const response = await request.get(`auth/local`, {
				auth: {
					username: values.username,
					password: values.password,
				},
			});
			const responseUser = await request.get(
				`api/user?access_token=${response.data.token}`
			);
			console.log(responseUser.data);
			localStorage.setItem(
				"current_user",
				JSON.stringify(responseUser.data)
			);
			localStorage.setItem("token", response.data.token);
			navigate("/app");
		} catch (error) {
			alert(error);
		}
	};

	return (
		<div className="box_signin overflow-hidden w-screen h-screen flex justify-start items-center">
			<div className="bg-white w-1/2 min-w-[600px] h-full gap-14 flex flex-col justify-between py-10 px-16">
				<div className="text-center flex flex-col">
					<img
						src={LogoSignIn}
						alt="logo"
						className="w-3/5 min-w-[300px] flex"
					/>
				</div>
				<Form
					ref={formRef}
					name="control-ref"
					onFinish={onFinish}
					style={{
						paddingLeft: 20,
						width: "70%",
						minWidth: "400px",
					}}
					layout="vertical"
				>
					<h1 className="text-[#09395e] font-semibold text-3xl">
						Đăng nhập!
					</h1>
					<h1 className="text-[#979797] font-normal text-base">
						Xin chào, vui lòng đăng nhập vào hệ thống
					</h1>
					<br />
					<Form.Item name="username" label="Email">
						<Input
							placeholder="Nhập tên / email"
							rules={[
								{
									required: true,
								},
							]}
							className="min-h-[42px]"
						/>
					</Form.Item>
					<Form.Item name="password" label="Mật khẩu">
						<Input
							type="password"
							placeholder="Nhập mật khẩu"
							rules={[
								{
									required: true,
								},
							]}
							className="min-h-[42px]"
						/>
					</Form.Item>
					<div className="flex justify-between items-center mb-1">
						<Form.Item
							name="remember"
							valuePropName="checked"
							noStyle
						>
							<Checkbox>
								<h4 className="font-normal text-base">
									Ghi nhớ
								</h4>
							</Checkbox>
						</Form.Item>
						<div
							className="mb-1 text-[#1A68FA] text-base font-normal cursor-pointer z-10"
							onClick={() => {
								navigate("/forgot-password");
							}}
						>
							Quên mật khẩu?
						</div>
					</div>
					<Form.Item className="flex justify-center w-full button_signin">
						<Button
							type="primary"
							htmlType="submit"
							className="min-h-[42px] w-full !bg-[#326b97] hover:!bg-[#09395e] hover:!text-white text-white font-medium !border-none"
						>
							Đăng nhập
						</Button>
					</Form.Item>
					<div className="mb-6 text-base font-normal cursor-pointer z-10 text-center w-full">
						Chưa có tài khoản tạo?{" "}
						<span
							onClick={() => {
								navigate("/sign-up");
							}}
							className="text-[#1A68FA]"
						>
							Tạo tài khoản ngay
						</span>
					</div>
				</Form>
			</div>
		</div>
	);
}
