import { Button, Checkbox, Input, Popconfirm, Tabs, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { GrAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import TableManager from "../../components/table/tableManager";
import "./post.scss";
import { request } from "../../../utils/request";
import moment from "moment";
const { Search } = Input;

export default function Post() {
	const [dataPost, setDataPost] = useState([]);
	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const handleDeletePost = async (id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/news/${id}`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
			handleGetDataPost();
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				description: error.message,
			});
		}
	};

	const columns = [
		{
			title: "Tiêu đề",
			dataIndex: "title",
			// fixed: "left",
			render: (title, record) => (
				<div className="flex flex-col field_title">
					<span>{title}</span>
					<div className="show_group_button">
						<span>
							<a
								href={`${localStorage.getItem(
									localStorage.getItem("id_app")
								)}blogs/${record?.slug}`}
								target="_blank"
								rel="noreferrer"
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#326b97]"
							>
								View
							</a>
							<div
								onClick={() => {
									navigate(
										`/posts/managerPost/${record?._id}`
									);
								}}
								className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div>
							<Popconfirm
								title={`Xoá "${record?.title}"`}
								onConfirm={() => {
									handleDeletePost(record?._id);
								}}
								onCancel={() => {
									return false;
								}}
								okText="Yes"
								cancelText="No"
							>
								<div className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]">
									Delete
								</div>
							</Popconfirm>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Tác giả",
			dataIndex: "name_user_created",
		},
		{
			title: "Loại",
			dataIndex: "category_name",
		},
		{
			title: "Tags",
			key: "exfields",
			dataIndex: "exfields",
			render: (_, record) => (
				<>
					{record?.exfields?.tags?.map((tag) => {
						let color = tag.length > 5 ? "geekblue" : "green";
						return (
							<Tag color={color} key={tag}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
		},
		{
			title: "Ngày tạo",
			dataIndex: "date_created",
			width: 200,
			render: (date_created, record) => {
				return (
					<div className="flex flex-col">
						<span>
							{record?.exfields?.status_post === 1
								? "Publish"
								: "Pending"}
						</span>
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];
	const handleGetDataPost = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem(
					"id_app"
				)}/news/?limit=500&access_token=flex.public.token`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataPost(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			//throw error;
		}
	};
	useEffect(() => {
		handleGetDataPost();
	}, []);

	const navigate = useNavigate();
	const onSearch = (value, _e, info) => console.log(info?.source, value);
	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};
	const TabContent = [
		{
			id: 1,
			label: "Đã duyệt",
			children: (
				<TableManager
					data={dataPost}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
		{
			id: 2,
			label: "Đang chờ duyệt",
			children: (
				<TableManager
					data={dataPost}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
		{
			id: 3,
			label: "Nỗi bật",
			children: (
				<TableManager
					data={dataPost}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
		{
			id: 4,
			label: "Từ chối",
			children: (
				<TableManager
					data={dataPost}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
	];
	return (
		<React.Fragment>
			{contextHolder}
			<div className="h-12 flex justify-between items-center pt-4">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Bài viết
				</div>
				<div className="flex justify-end gap-5 items-center box_menu_post">
					<div className="flex justify-end items-center gap-3">
						<Button
							onClick={() => {
								navigate("/categories");
							}}
							className="!border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
						>
							Loại bài viết
						</Button>
						{/* <Button
							onClick={() => {
								navigate("/author");
							}}
							className="!border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
						>
							Author
						</Button>
						<Button
							onClick={() => {
								navigate("/tags");
							}}
							className="!border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
						>
							Tags
						</Button> */}
					</div>
					<Button
						onClick={() => {
							navigate("/posts/managerPost/create");
						}}
						className="!border-[#09395e] hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1"
					>
						<GrAdd /> Thêm mới
					</Button>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex justify-between items-center pt-4">
					<div>
						<Search
							placeholder="Từ khoá ..."
							allowClear
							onSearch={onSearch}
							style={{
								width: 300,
							}}
						/>
					</div>
					{/* <div className="flex justify-between items-end gap-3 box_menu_post">
						<Checkbox onChange={onChange}>
							Show all categories
						</Checkbox>
						<Checkbox onChange={onChange}>Show all tags</Checkbox>
						<Checkbox onChange={onChange}>
							Show all authors
						</Checkbox>
					</div> */}
				</div>
				<div className="box_content">
					{/* <Tabs
						defaultActiveKey="1"
						size={"middle"}
						items={TabContent.map((item) => {
							return {
								label: (
									<p className="text-[#326b97] hover:text-[#09395e]">
										{item.label}
									</p>
								),
								key: item.id,
								children: item.children,
							};
						})}
						className="!mb-0 border-b-[1px] border-slate-400"
					/> */}
					<TableManager
						data={dataPost}
						columns={columns}
						loading={loading}
						scroll={{ y: "calc(100vh - 332px)" }}
						className="border-b-[1px] border-slate-400"
					/>
				</div>
			</div>
		</React.Fragment>
	);
}
