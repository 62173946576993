import { PlusOutlined } from "@ant-design/icons";
import {
	Button,
	Checkbox,
	Image,
	Input,
	Modal,
	Radio,
	Select,
	Space,
	Spin,
	Upload,
	message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import _, { set } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AiOutlineMinus } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { IoAdd, IoSaveOutline } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import unidecode from "unidecode";
import { request } from "../../../../utils/request";
import TemplatePage from "../../../Template/TemplatePos/templatePage";
import PopoverModel from "../../../components/modal/popoverModel";
import { FiSave } from "react-icons/fi";
import "./createPage.scss";
import PopupModel from "../../../components/modal/popupModel";
import ListSection from "../TemplatePage/ListSection/ListSection";
import { HiOutlineLogout } from "react-icons/hi";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export default function CreatePage() {
	const navigate = useNavigate();
	// React state to track order of items
	const [dataTemplatePage, setDataTemplatePage] = useState([]);

	const [messageApi, contextHolder] = message.useMessage();
	const [dataPage, setDataPage] = useState({
		title: "Page Name",
		exfields: {
			status: "0",
			url: "page-name",
		},
	});
	const [loading, setLoading] = useState(false);

	const handleGetDataItemPage = async (idTempPage) => {
		setLoading(true);
		try {
			const response = await request.get(
				`api/${localStorage.getItem(
					"id_app"
				)}/webpagedetails/${idTempPage}`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataPage(response?.data);
			setDataTemplatePage(response?.data?.exfields?.data);
			// console.log(response?.data?.exfields?.data, "data Page");
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	const handleGetDataItemTemplate = async (idTemplate) => {
		setLoading(true);
		try {
			const response = await request.get(
				`api/${localStorage.getItem(
					"id_app"
				)}/webtemplate/${idTemplate}`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataTemplatePage(response?.data?.exfields?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	const href = window.location.href;
	const idPage = href.split("/")[href.split("/").length - 1];
	const [searchParams] = useSearchParams();
	const idTemplate = searchParams.get("template");
	const idTempPage = searchParams.get("page");

	useEffect(() => {
		handleGetDataSections();
		handleGetUserFiles();
	}, []);

	useEffect(() => {
		if (idPage !== "create") {
			handleGetDataItemPage(idTempPage);
		} else {
			if (idTemplate) {
				handleGetDataItemTemplate(idTemplate);
			} else {
				localStorage.getItem("dataTemplate") &&
					setDataTemplatePage(
						JSON.parse(localStorage.getItem("dataTemplate"))
					);
			}
		}
	}, [idPage, idTemplate, idTempPage]);

	useEffect(() => {
		setDataPage({
			...dataPage,
			exfields: {
				...dataPage.exfields,
				data: dataTemplatePage,
			},
		});
	}, [dataTemplatePage]);

	const [dataImage, setDataImage] = useState([]);

	const [isChooseImage, setIsChooseImage] = useState(false);
	const handleChooseImage = () => {
		setIsChooseImage(true);
	};
	const handleCancelChooseImage = () => {
		setIsChooseImage(false);
	};
	const handleSaveImage = () => {
		if (dataPage.image === "") {
			handleSavePicture();
		}
		setIsChooseImage(false);
	};

	const handleGetUserFiles = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
					params: {
						t: 1,
						page: 1,
						limit: 500,
					},
				}
			);
			setDataImage(response.data);
			setLoading(false);
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleSaveUserFile = async (file) => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					type: "image",
					link: `https://api.goodapp.vn${file}`,
					exfields: {
						name: "image",
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			handleGetUserFiles();
		} catch (error) {
			console.log(error);
		}
	};

	//Title page
	const onChangeTitle = (e) => {
		setDataPage({
			...dataPage,
			title: e.target.value,
			exfields: {
				...dataPage.exfields,
				url: _.chain(unidecode(e.target.value))
					.trim() // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
					.deburr() // Loại bỏ dấu
					.toLower() // Chuyển thành chữ thường
					.replace(/ /g, "-") // Thay thế khoảng trắng bằng dấu -
					.value(), // Lấy giá trị cuối cùng của chuỗi xử lý,
			},
		});
	};

	//Trạng thái hiển thị của page
	const dataStatus = [
		{
			value: "0",
			label: "Công khai",
			description: "Hiển thị tới tất cả.",
		},
		{
			value: "1",
			label: "Riêng tư",
			description: "Chỉ hiển thị cho quản trị viên và biên tập viên",
		},
		{
			value: "2",
			label: "Mật khẩu",
			description:
				"Chỉ những người có mật khẩu mới có thể xem bài viết này.",
		},
	];
	const onChangeStatus = (e) => {
		setDataPage({
			...dataPage,
			exfields: {
				...dataPage.exfields,
				status: e.target.value,
			},
		});
	};

	//Url
	const onChangeUrl = (e) => {
		setDataPage({
			...dataPage,
			exfields: {
				...dataPage.exfields,
				url: e.target.value?.trim().replace(/ /g, "-"),
			},
		});
	};

	//Chờ duyệt
	const onChangePending = (e) => {
		setDataPage({
			...dataPage,
			pending: e.target.checked,
		});
	};

	//Chọn tác giả
	const handleChangeAuthor = (value) => {
		setDataPage({
			...dataPage,
			author: value,
		});
	};

	//Gắn tags
	const options = [];
	const handleChangeTags = (value) => {
		setDataPage({
			...dataPage,
			exfields: {
				...dataPage.exfields,
				tags: value,
			},
		});
	};

	//Trạng thái bình luận
	const onChangeComment = (e) => {
		setDataPage({
			...dataPage,
			comment: e.target.checked,
		});
	};

	//upload image
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const handleCancel = () => setPreviewOpen(false);
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};
	const handleChange = ({ fileList: newFileList }) =>
		setFileList(newFileList);
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</div>
	);
	const uploadImageApi = async (file) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await request.post(
				`api/uploadfile?json=1&folder=files`,
				formData,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);

			const imageUrl = response.data.fileUrl;
			return imageUrl;
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	const handleSavePicture = async () => {
		try {
			const res = await uploadImageApi(fileList[0].originFileObj);
			await handleSaveUserFile(res);
			setDataPage({
				...dataPage,
				image: `https://api.goodapp.vn${res}`,
			});
			setDataImage([
				...dataImage,
				{
					link: `https://api.goodapp.vn${res}`,
				},
			]);
		} catch (error) {
			console.log(error);
		}
	};

	const beforeUpload = (file) => {
		// Trả về false để ngăn chặn tải lên tự động khi chọn tệp
		setDataPage({
			...dataPage,
			image: "",
		});
		return false;
	};

	//Gắn keywords seo
	const optionsKeySEO = [];
	const handleChangeKeySEO = (value) => {
		setDataPage({
			...dataPage,
			exfields: { ...dataPage.exfields, keywords_seo: value },
		});
	};

	const onChangeTitleSEO = (e) => {
		setDataPage({
			...dataPage,
			exfields: {
				...dataPage.exfields,
				title_seo: e.target.value,
			},
		});
	};

	const onChangeDescription = (e) => {
		setDataPage({
			...dataPage,
			exfields: {
				...dataPage.exfields,
				description: e.target.value,
			},
		});
	};

	//Manager page
	const handleSaveTemplatePage = async (link) => {
		if (dataPage?.title === "" || dataPage?.title === undefined) {
			messageApi.open({
				type: "error",
				content: "Nhập Title trước khi lưu!!!",
			});
		} else {
			if (idPage !== "create") {
				setLoading(true);
				try {
					await request.put(
						`api/${localStorage.getItem(
							"id_app"
						)}/webpagedetails/${idTempPage}`,
						dataPage,
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
					setLoading(false);
					messageApi.open({
						type: "success",
						content: "Thay đổi thành công",
					});
					link && navigate(link);
				} catch (error) {
					setLoading(false);
					messageApi.open({
						type: "error",
						content: "Thay đổi thất bại",
					});
					console.log(error);
					//throw error;
				}
			} else {
				setLoading(true);
				try {
					const response = await request.post(
						`api/${localStorage.getItem("id_app")}/webpagedetails`,
						dataPage,
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
					setLoading(false);
					messageApi.open({
						type: "success",
						content: "Tạo thành công",
					});
					link
						? navigate(link)
						: navigate(
								`/pages/managerPage/edit?page=${response?.data?._id}`
						  );
				} catch (error) {
					setLoading(false);
					messageApi.open({
						type: "error",
						content: "Tạo thất bại",
					});
					console.log(error);
					//throw error;
				}
			}
		}
	};

	const handleDeleteTemplatePage = async () => {
		if (idTempPage) {
			try {
				setLoading(true);
				await request.delete(
					`api/${localStorage.getItem(
						"id_app"
					)}/webpagedetails/${idTempPage}`,
					{
						headers: {
							"X-Access-Token": localStorage.getItem("token"),
							"Content-Type": "application/json",
						},
					}
				);
				setLoading(false);
				messageApi.open({
					type: "success",
					content: "Xoá thểt bị",
				});
				navigate("/pages");
			} catch (error) {
				setLoading(false);
				messageApi.open({
					type: "error",
					content: "Xoá thất bại",
				});
				console.log(error);
				//throw error;
			}
		} else {
			navigate("/pages");
		}
	};

	const handleSaveTemplate = async () => {
		setLoading(true);
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/webtemplate`,
				{
					title: dataPage.title,
					exfields: {
						data: dataTemplatePage,
						status_template: 0,
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			messageApi.open({
				type: "success",
				content: "Tạo template thành công",
			});
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Tạo template thất bại",
			});
			console.log(error);
			//throw error;
		}
	};

	const [openListSection, setOpenListSection] = useState(false);
	const handleCancelListSection = () => {
		setOpenListSection(false);
	};
	const handleAddSectionTemplate = (newDataSection) => {
		setDataTemplatePage([...dataTemplatePage, ...newDataSection]);
		setOpenListSection(false);
		setAddSectionTemplate(false);
	};

	// Data Section
	const [dataListSections, setDataListSections] = useState([]);
	const handleGetDataSections = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/websection`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataListSections(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	//

	// Function to update list on drop
	const [addSectionTemplate, setAddSectionTemplate] = useState(false);
	const handleDrop = (droppedItem) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...dataTemplatePage];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		// Update State
		console.log(updatedList);
		setDataTemplatePage(updatedList);
	};
	const handleAddSection = () => {
		setOpenListSection(true);
		setAddSectionTemplate(true);
	};
	const handleDeleteSection = (indexToDelete) => {
		const dataPageClone = [...dataTemplatePage];
		const newDataPageClone = dataPageClone.filter(
			(item, index) => index !== indexToDelete
		);
		setDataTemplatePage(newDataPageClone);
	};

	return (
		<Spin spinning={loading}>
			<div className="box_create_page flex h-[calc(100vh-54px)] max-h-[calc(100vh-54px)]">
				{contextHolder}
				<div className="w-[calc(100vw-350px)] h-full bg-slate-50 border">
					<TemplatePage
						dataTemplatePage={dataTemplatePage}
						setDataTemplatePage={setDataTemplatePage}
					/>
				</div>
				<div className="w-[250px] h-full min-w-[350px]">
					<div className="flex justify-between items-center px-2 py-[6px] border-b mb-4">
						<span className="font-bold text-lg text-[#326b97]">
							Thông tin
						</span>
						<div className="flex justify-end items-center gap-[2px]">
							<Button
								title="Save Template"
								type="text"
								onClick={handleSaveTemplate}
								className="flex gap-2 px-2 items-center !border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave /> Template
							</Button>
							<hr className="w-[1px] h-4 bg-slate-600" />
							<Button
								title="Save"
								type="submit"
								onClick={() => {
									handleSaveTemplatePage();
								}}
								className="px-2 !border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave />
							</Button>
							<hr className="w-[1px] h-4 bg-slate-600" />
							<Button
								title="Save And Out"
								type="submit"
								onClick={() => {
									handleSaveTemplatePage("/pages");
								}}
								className="px-2 flex items-center gap-2 !border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave /> & <HiOutlineLogout />
							</Button>
						</div>
					</div>
					<div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-4 border-b pb-4 h-[calc(100%-60px)] max-h-[calc(100%-60px)] overflow-auto">
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Title Page
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<Input
										placeholder="title page"
										defaultValue={dataPage.title}
										onChange={onChangeTitle}
									/>
								}
								title={
									<span className="text-xs font-normal">
										Title Page
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs cursor-pointer hover:underline">
									{dataPage?.title}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Hiển thị
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<Radio.Group
										der
										name="radiogroup"
										defaultValue={
											dataPage?.exfields?.status
										}
										size="small"
										onChange={onChangeStatus}
									>
										<Space direction="vertical">
											{dataStatus.map((item, index) => {
												return (
													<>
														<Radio
															value={item.value}
														>
															<span className="text-[#326b97] text-[10px]">
																{item.label}
															</span>
														</Radio>
														<span className="text-[10px] pl-6">
															{item.description}
														</span>
													</>
												);
											})}
										</Space>
									</Radio.Group>
								}
								title={
									<span className="text-xs font-normal">
										Hiển thị
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs cursor-pointer hover:underline">
									{
										dataStatus[dataPage?.exfields?.status]
											?.label
									}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Đăng
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<span className="text-[#09395e] text-xs">
								{moment(dataPage.date_created).format(
									"DD/MM/YYYY"
								)}
							</span>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Url
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<div className="flex flex-col">
										<Input
											placeholder="path page"
											defaultValue={dataPage?.exfields?.url
												?.trim()
												.replace(/-/g, " ")}
											onChange={onChangeUrl}
										/>
										<span className="uppercase text-[10px] mt-3 pl-1">
											View page
										</span>
										<a
											href={`${localStorage.getItem(
												localStorage.getItem("id_app")
											)}${dataPage?.exfields?.url}`}
											target="_blank"
											rel="noreferrer"
										>
											<div className="pl-1 text-[#09395e] text-[10px] hover:underline overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer w-[300px]">
												{localStorage.getItem(
													localStorage.getItem(
														"id_app"
													)
												) + dataPage?.exfields?.url}
											</div>
										</a>
									</div>
								}
								title={
									<span className="text-xs font-normal">
										Đường dẫn tĩnh
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs hover:underline overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer">
									{localStorage.getItem(
										localStorage.getItem("id_app")
									) + dataPage?.exfields?.url}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<Checkbox onChange={onChangePending}>
								<span className="text-[#326b97] text-xs">
									Chờ duyệt
								</span>
							</Checkbox>
						</div>
						<div className="flex flex-col gap-3 px-2 w-full">
							{/* <span className="text-[#326b97] text-xs uppercase text-left">
								Tác giả
							</span>
							<Select
								style={{
									width: "300px",
								}}
								onChange={handleChangeAuthor}
								defaultValue={"admin"}
								options={[
									{
										value: "admin",
										label: "Admin",
									},
									{
										value: "user1",
										label: "User1",
									},
									{
										value: "user2",
										label: "User2",
									},
								]}
							/> */}
							<span className="text-[#326b97] text-xs uppercase text-left">
								Tags
							</span>
							<Select
								mode="tags"
								style={{
									width: "100%",
								}}
								onChange={handleChangeTags}
								tokenSeparators={[","]}
								options={options}
								value={dataPage?.exfields?.tags}
							/>
							<div className="flex flex-col justify-start items-start text-left gap-3 w-full">
								<span className="text-[#326b97] text-xs uppercase text-left">
									Thảo luận
								</span>
								<Checkbox onChange={onChangeComment}>
									<span className="text-[#326b97] text-xs">
										Cho phép bình luận
									</span>
								</Checkbox>
							</div>

							<hr className="w-full h-1 mt-3" />

							<DragDropContext onDragEnd={handleDrop}>
								<span className="text-[#326b97] text-xs uppercase text-left w-full flex justify-start items-center gap-3">
									Các Section tạo Page{" "}
									<Button
										size="small"
										shape="circle"
										className="flex justify-center items-center"
										onClick={handleAddSection}
									>
										<IoAdd />
									</Button>
								</span>
								<Droppable droppableId="list-container">
									{(provided) => (
										<div
											className="list-container flex flex-col justify-start items-center"
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											{dataTemplatePage.map(
												(item, index) => (
													<Draggable
														key={
															JSON.stringify(
																item
															) + index
														}
														draggableId={
															JSON.stringify(
																item
															) + index
														}
														index={index}
													>
														{(provided) => (
															<div
																className=" px-2 flex justify-between items-center w-full h-[40px] bg-[#5db3f531] rounded-md cursor-pointer border border-white hover:border-[#09395e]"
																ref={
																	provided.innerRef
																}
																{...provided.dragHandleProps}
																{...provided.draggableProps}
															>
																{item.title}
																<Button
																	size="small"
																	shape="circle"
																	className="flex justify-center items-center"
																	onClick={() => {
																		handleDeleteSection(
																			index
																		);
																	}}
																	type="text"
																>
																	<AiOutlineMinus />
																</Button>
															</div>
														)}
													</Draggable>
												)
											)}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>

							<hr className="w-full h-1 mt-3" />

							<div className="flex flex-col justify-start items-start gap-3">
								<span className="text-[#326b97] text-xs uppercase text-left flex justify-start items-center gap-3">
									Hình ảnh
									{dataPage?.image && (
										<Button
											shape="circle"
											type="text"
											className="flex justify-center items-center"
											size="small"
										>
											<CiEdit
												onClick={handleChooseImage}
											/>
										</Button>
									)}
								</span>
								{dataPage?.image ? (
									<Image
										width={96}
										height={96}
										className="rounded-lg object-cover"
										src={dataPage?.image}
									/>
								) : (
									<div
										onClick={handleChooseImage}
										className="w-24 h-24 rounded-lg bg-slate-50 border border-dashed flex flex-col justify-center items-center text-slate-500 hover:border-[#09395e] cursor-pointer"
									>
										<PlusOutlined />
										<div
											style={{
												marginTop: 8,
											}}
										>
											Upload
										</div>
									</div>
								)}
								<Modal
									title="Chọn hình ảnh"
									open={isChooseImage}
									footer={null}
									onCancel={handleCancelChooseImage}
								>
									<p>
										<Upload
											beforeUpload={beforeUpload}
											listType="picture-card"
											fileList={fileList}
											onPreview={handlePreview}
											onChange={handleChange}
										>
											{fileList.length >= 1
												? null
												: uploadButton}
										</Upload>
									</p>
									<p className="my-3 font-semibold flex justify-start items-center gap-3">
										Thư viện
										<Button
											shape="circle"
											type="text"
											className="flex justify-center items-center"
											size="small"
										>
											<IoSaveOutline
												onClick={handleSaveImage}
											/>
										</Button>
									</p>
									<div className="flex flex-wrap gap-3">
										{dataImage?.map((item) => (
											<div
												onClick={() => {
													setDataPage({
														...dataPage,
														image: item.link,
													});
												}}
												className={`${
													dataPage?.image ===
													item.link
														? "border-[#09395e]"
														: ""
												} border w-24 h-24 rounded-lg hover:border-[#09395e] cursor-pointer`}
											>
												<Image
													width={96}
													height={96}
													className="rounded-lg object-contain bg-slate-50"
													src={item.link}
													preview={false}
												/>
											</div>
										))}
									</div>
								</Modal>
								<Modal
									open={previewOpen}
									title={previewTitle}
									footer={null}
									onCancel={handleCancel}
								>
									<img
										alt="example"
										style={{
											width: "100%",
										}}
										src={previewImage}
									/>
								</Modal>
								<span className="text-[#326b97] text-xs uppercase text-left">
									Keywords SEO
								</span>
								<Select
									mode="tags"
									style={{
										width: "100%",
									}}
									onChange={handleChangeKeySEO}
									tokenSeparators={[","]}
									options={optionsKeySEO}
									value={dataPage?.exfields?.keywords_seo}
								/>
								<span className="text-[#326b97] text-xs uppercase text-left">
									Meta title
								</span>{" "}
								<TextArea
									rows={2}
									onChange={onChangeTitleSEO}
									value={dataPage?.exfields?.title_seo}
								/>
								<span className="text-[#326b97] text-xs uppercase text-left">
									meta description
								</span>{" "}
								<TextArea
									rows={3}
									onChange={onChangeDescription}
									value={dataPage?.exfields?.description}
								/>
							</div>
							<hr className="w-full h-1 mt-3" />
							<Button
								type="submit"
								onClick={handleDeleteTemplatePage}
								className="!border-[#c02612] hover:!bg-slate-50 hover:!border-[#c02612] hover:!text-[#c02612] text-[#c44b3b] font-medium shadow-none flex justify-center items-center gap-1 w-full"
							>
								Di chuyển đến thùng rác
							</Button>
						</div>
					</div>
				</div>
			</div>
			<PopupModel
				title={"Chọn section cho Page"}
				style={{
					top: "30px",
				}}
				open={openListSection}
				onCancel={handleCancelListSection}
				width={"70vw"}
				footer={null}
			>
				<ListSection
					type={"page"}
					action={addSectionTemplate}
					dataSections={dataListSections}
					messageApi={messageApi}
					setLoading={setLoading}
					close={handleCancelListSection}
					handleAddSectionTemplate={handleAddSectionTemplate}
				/>
			</PopupModel>
		</Spin>
	);
}
