import { Button, Checkbox } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../../../../../utils/request";

export default function ListSection({
	type,
	action = false,
	dataSections,
	messageApi,
	setLoading,
	close,
	handleAddSectionTemplate,
}) {
	const navigate = useNavigate();
	const [dataTemplate, setDataTemplate] = useState([]);
	const onChange = async (checkedValues) => {
		console.log("checked = ", checkedValues);
		if (type === "page") {
			if (action) {
				setDataTemplate(checkedValues);
			} else {
				localStorage.setItem(
					"dataTemplate",
					JSON.stringify(checkedValues)
				);
			}
		} else {
			setDataTemplate(checkedValues);
		}
	};
	const handleSaveTemplate = async (dataTemplatePage) => {
		setLoading(true);
		try {
			const response = await request.post(
				`api/${localStorage.getItem("id_app")}/webtemplate`,
				{
					title: "New Template",
					exfields: {
						data: dataTemplatePage,
						status_template: 0,
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			messageApi.open({
				type: "success",
				content: "Tạo template thành công",
			});
			navigate(
				`/templates-web/managerTemplate/edit?template=${response?.data?._id}`
			);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Tạo template thất bại",
			});
			console.log(error);
			//throw error;
		}
	};

	const handleCreateTemplate = () => {
		if (action) {
			handleAddSectionTemplate(dataTemplate);
		} else {
			if (type === "page") {
				navigate(`/pages/managerPage/create`);
			} else {
				close();
				handleSaveTemplate(dataTemplate);
			}
		}
	};
	return (
		<Checkbox.Group
			style={{
				width: "100%",
			}}
			onChange={onChange}
		>
			<Button
				onClick={() => handleCreateTemplate()}
				className="px-4 mt-2"
			>
				Tạo {type}
			</Button>
			<div className="flex justify-start items-center flex-wrap gap-2 py-3 max-h-[calc(80vh-32px)] overflow-auto">
				{dataSections?.map((item, index) => (
					<div
						key={index}
						className="flex flex-col gap-2 items-center w-[calc(25%-8px)] min-w-[200px] h-44 bg-slate-100 cursor-pointer p-2 rounded-lg border border-white hover:border-[#09395e]"
					>
						<div className="w-full h-4/5">
							<img
								src={
									item?.exfields?.data?.image ||
									"https://api.goodapp.vn/getfile/files/6498312796f391dad61d71d7_1697377814844_city.svg"
								}
								alt="Section4"
								className="w-full h-full object-contain"
							/>
						</div>
						<Checkbox value={item}>
							<p>{item?.title}</p>
						</Checkbox>
					</div>
				))}
			</div>
		</Checkbox.Group>
	);
}
