import { Fragment } from "react";
import { BrowserRouter, Navigate, Route, Routes,HashRouter as Router } from "react-router-dom";
import isSignIn from "../../utils/isSignIn";
import LayoutDefault from "../layout/LayoutDefault";
import { privateRouter, publicRouter } from "../routers";
import "./App.css";

const RouteElement = ({ isPrivate, children }) => {
	const isAuth = isSignIn();
	return isAuth || !isPrivate ? <>{children}</> : <Navigate to="/sign-in" />;
};

function App() {
	const routers = [
		...publicRouter,
		...privateRouter.map((route) => ({
			...route,
			isPrivate: true,
		})),
	];
	return (
		<Router>
			<div className="App">
				<Routes>
					{routers?.map((route, index) => {
						const Page = route.component;
						let Layout = LayoutDefault;
						if (route.layout) {
							Layout = route.layout;
						} else if (route.layout === null) {
							Layout = Fragment;
						}
						return (
							<Route
								key={index}
								path={route.path}
								element={
									<RouteElement isPrivate={route.isPrivate}>
										<Layout name={route.name}>
											<Page />
											{/* <Toaster
												position="bottom-right"
												reverseOrder={false}
												toastOptions={{
													duration: 2000,
													style: {
														padding: "16px",
													},
													success: {
														style: {
															border: "1px solid #00b74a",
														},
													},
													error: {
														style: {
															border: "1px solid #ff3d57",
														},
													},
												}}
											/> */}
										</Layout>
									</RouteElement>
								}
							/>
						);
					})}
				</Routes>
			</div>
		</Router>
	);
}

export default App;
