import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import PopoverModel from "../../../components/modal/popoverModel";
import UploadImage from "../../components/uploadImage";
import "../section.scss";
import { PiSelectionBackgroundThin } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";

export default function SectionBanner({ data, handleUpdateData }) {
	const [dataSection, setDataSection] = useState(data || {});

	//upload image background
	const [isChooseBackgroundImage, setIsChooseBackgroundImage] =
		useState(false);
	const handleChooseBackgroundImage = () => {
		setIsChooseBackgroundImage(true);
	};
	const handleCancelChooseBackgroundImage = () => {
		setIsChooseBackgroundImage(false);
	};

	//upload image
	const [isChooseImage, setIsChooseImage] = useState(false);
	const handleChooseImage = () => {
		setIsChooseImage(true);
	};
	const handleCancelChooseImage = () => {
		setIsChooseImage(false);
	};

	useEffect(() => {
		handleUpdateData(dataSection);
	}, [dataSection]);

	return (
		<React.Fragment>
			<section
				style={
					dataSection.backgroundImage
						? {
								backgroundImage: `url(${dataSection.backgroundImage})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
						  }
						: {}
				}
				className="box_banner_1 relative flex items-center"
			>
				<div className="absolute top-2 left-2">
					<Button
						shape="circle"
						type="text"
						className="mb-1 flex justify-center items-center !bg-slate-200 hover:!bg-white"
						size="middle"
						onClick={handleChooseBackgroundImage}
					>
						<PiSelectionBackgroundThin size={22} />
					</Button>
					{dataSection.backgroundImage && (
						<Button
							shape="circle"
							type="text"
							className="flex justify-center items-center !bg-slate-200 hover:!bg-white"
							size="middle"
							onClick={() => {
								setDataSection({
									...dataSection,
									backgroundImage: "",
								});
							}}
						>
							<AiOutlineClose size={22} />
						</Button>
					)}
				</div>
				<div className="box_banner_1_left w-1/2 text-[#09395e] flex justify-center items-center">
					<div className="box_banner_1_left_content w-2/3">
						<PopoverModel
							content={
								<Input.TextArea
									placeholder="nội dung"
									defaultValue={dataSection.title}
									onChange={(e) =>
										setDataSection({
											...dataSection,
											title: e.target.value,
										})
									}
									rows={4}
									className="w-[300px]"
								/>
							}
							title={
								<span className="text-xs font-normal">
									Thay đổi
								</span>
							}
							trigger="click"
							arrow={true}
						>
							<h1 className="!text-4xl !font-bold pb-5 text-left hover:bg-slate-50">
								{dataSection.title || "Tiêu đề"}
							</h1>{" "}
						</PopoverModel>
						<PopoverModel
							content={
								<Input.TextArea
									placeholder="nội dung"
									defaultValue={dataSection.sort_description}
									onChange={(e) =>
										setDataSection({
											...dataSection,
											sort_description: e.target.value,
										})
									}
									rows={4}
									className="w-[300px]"
								/>
							}
							title={
								<span className="text-xs font-normal">
									Thay đổi
								</span>
							}
							trigger="click"
							arrow={true}
						>
							<p className="!text-xl py-3 text-left hover:bg-slate-50">
								{dataSection.sort_description || "Mô tả ngắn"}
							</p>
						</PopoverModel>
						<PopoverModel
							content={
								<Input.TextArea
									placeholder="nội dung"
									defaultValue={dataSection.description}
									onChange={(e) =>
										setDataSection({
											...dataSection,
											description: e.target.value,
										})
									}
									rows={4}
									className="w-[300px]"
								/>
							}
							title={
								<span className="text-xs font-normal">
									Thay đổi
								</span>
							}
							trigger="click"
							arrow={true}
						>
							<p className="!text-xl py-3 text-left hover:bg-slate-50">
								{" "}
								{dataSection.description || "Mô tả"}{" "}
							</p>
						</PopoverModel>
						<PopoverModel
							content={
								<div className="flex flex-col gap-3">
									<Input
										placeholder="Name"
										defaultValue={dataSection?.button?.name}
										onChange={(e) =>
											setDataSection({
												...dataSection,
												button: {
													...dataSection?.button,
													name: e.target.value,
												},
											})
										}
										className="w-[200px]"
									/>
									<Input
										placeholder="Url"
										defaultValue={dataSection?.button?.url}
										onChange={(e) =>
											setDataSection({
												...dataSection,
												button: {
													...dataSection?.button,
													url: e.target.value,
												},
											})
										}
										className="w-[200px]"
									/>
								</div>
							}
							title={
								<span className="text-xs font-normal">
									Thay đổi
								</span>
							}
							trigger="click"
							arrow={true}
						>
							<Button className="btn-grad_2 !rounded-3xl !px-8 font-medium">
								{dataSection.button?.name || "Button"}
							</Button>
						</PopoverModel>
					</div>
				</div>
				<div
					onClick={handleChooseImage}
					className="box_banner_1_right w-1/2 hover:bg-slate-50 cursor-pointer"
				>
					<span>
						<img
							src={
								dataSection.image ||
								"https://api.goodapp.vn/getfile/files/6498312796f391dad61d71d7_1697377814844_city.svg"
							}
							alt="ImagePosBanner"
							className="max-h-[270px] w-full object-contain"
						/>
					</span>
				</div>
				<UploadImage
					setData={setDataSection}
					data={dataSection}
					isChooseImage={isChooseImage}
					handleCancelChooseImage={handleCancelChooseImage}
				/>
				<UploadImage
					setDataItem={(data) => {
						setDataSection({
							...dataSection,
							backgroundImage: data,
						});
					}}
					data={dataSection}
					isChooseImage={isChooseBackgroundImage}
					handleCancelChooseImage={handleCancelChooseBackgroundImage}
				/>
			</section>
			<section className="box_banner flex">
				<div></div>
			</section>
		</React.Fragment>
	);
}
