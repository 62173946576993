import { Form, Input } from "antd";
import React from "react";

export default function InfoCategory() {
	return (
		<div className="flex flex-col gap-3">
			<Form.Item
				name="ten_nvt"
				label="Tên nhóm vật tư"
				rules={[{ required: true }]}
			>
				<Input placeholder="Nhập tên nhóm vật tư" />
			</Form.Item>
		</div>
	);
}
