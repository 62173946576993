import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { request } from "../../../utils/request";

export default function InputSelect({
	isDisabled,
	placeholder,
	valueField = "id",
	labelField = "name",
	multi = false,
	api,
	value,
	onChange,
	onFocus,
	limit = 10,
	isClearable = true,
	defaultValue,
	classNamePrefix,
	q,
	prefix,
	filter,
	getLabel,
	components,
	className,
	valueSelect,
}) {
	const handelGetData = async ({ endpoint, params }) => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/${endpoint}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
					params: {
						...params,
					},
				}
			);
			return response;
		} catch (error) {
			console.log(error.message);
		}
	};

	const loadOptions = async (search, prevOptions) => {
		const query = {
			...q,
		};

		if (search) {
			if (!query.$or) query.$or = [];

			query.$or.push({
				[labelField]: {
					$regex: search,
					$options: "i",
				},
			});

			if (valueField !== "_id" && valueField !== labelField) {
				query.$or.push({
					[valueField]: {
						$regex: prefix ? `^${search}` : search,
						$options: "i",
					},
				});
			}
		}

		if (!search) delete query.$or;

		const { data } = await handelGetData({
			endpoint: api,
			params: {
				page: prevOptions.length / limit + 1,
				limit,
				q: query,
				...filter,
			},
		});

		return {
			options: data.map((item) => ({
				...item,
				value: item[valueField],
				label: getLabel?.(item) || item[labelField],
			})),
			hasMore: data.length === limit,
		};
	};

	return (
		<AsyncPaginate
			isMulti={multi}
			isClearable={isClearable}
			isDisabled={isDisabled}
			placeholder={placeholder}
			value={valueSelect}
			loadOptions={loadOptions}
			debounceTimeout={500}
			menuPlacement="auto"
			classNamePrefix={classNamePrefix}
			noOptionsMessage={() => "Không có dữ liệu"}
			className={className}
			onChange={onChange}
			onFocus={onFocus}
			components={components}
			defaultOptions
			defaultValue={defaultValue}
			isSearchable
		/>
	);
}
