import { Button, Table } from "antd";
import React, { useState } from "react";

const columns = [
	{
		title: "Name",
		dataIndex: "name",
	},
	{
		title: "Age",
		dataIndex: "age",
	},
	{
		title: "Address",
		dataIndex: "address",
	},
];
const data = [];
for (let i = 0; i < 46; i++) {
	data.push({
		key: i,
		name: `Edward King ${i}`,
		age: 32,
		address: `London, Park Lane no. ${i}`,
	});
}

export default function Blogs() {
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [loading, setLoading] = useState(false);
	const start = () => {
		setLoading(true);
		// ajax request after empty completing
		setTimeout(() => {
			setSelectedRowKeys([]);
			setLoading(false);
		}, 1000);
	};
	const onSelectChange = (newSelectedRowKeys, selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		console.log(selectedRows);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const hasSelected = selectedRowKeys.length > 0;
	return (
		<div>
			{/* <div
				style={{
					marginBottom: 16,
				}}
			>
				<Button
					type="primary"
					onClick={start}
					disabled={!hasSelected}
					loading={loading}
				>
					Reload
				</Button>
				<span
					style={{
						marginLeft: 8,
					}}
				>
					{hasSelected
						? `Selected ${selectedRowKeys.length} items`
						: ""}
				</span>
			</div> */}
			<Table
				rowSelection={rowSelection}
				columns={columns}
				dataSource={data}
			/>
		</div>
	);
}
