import { AiOutlineHome, AiOutlineSetting } from "react-icons/ai";
import { BiBookContent, BiSupport } from "react-icons/bi";
import { BsArrowReturnRight, BsChatLeftText } from "react-icons/bs";
import { RiWechatChannelsLine } from "react-icons/ri";
import { FaBuysellads } from "react-icons/fa6";

const ItemMenuLeft = [
	{
		name: "Dashboard",
		path: "/",
		title: "Dashboard",
		icon: <AiOutlineHome size={24} />,
	},
	{
		name: "Content",
		path: "/content",
		title: "Content",
		icon: <BiBookContent size={24} />,
		tabItem: [
			{
				parent: "/content",
				name: "Posts",
				path: "/posts",
				title: "Posts",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/content",
				name: "Pages",
				path: "/pages",
				title: "Pages",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/content",
				name: "Customer Feedback",
				path: "/feedback",
				title: "Customer Feedback",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/content",
				name: "Services",
				path: "/services",
				title: "Services",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/content",
				name: "Products",
				path: "/products",
				title: "Products",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/content",
				name: "Media Library",
				path: "/media-library",
				title: "Media Library",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/content",
				name: "Menu Management",
				path: "/menu",
				title: "Menu Management",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Communicate",
		path: "/communicate",
		title: "Communicate",
		icon: <BsChatLeftText size={24} />,
		tabItem: [
			{
				parent: "/communicate",
				name: "Comments",
				path: "/comments",
				title: "Comments",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/communicate",
				name: "Chat bot",
				path: "/chat-bot",
				title: "Chat bot",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/communicate",
				name: "Email",
				path: "/email",
				title: "Email",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Marketing",
		path: "/marketing",
		title: "Marketing",
		icon: <FaBuysellads size={24} />,
		tabItem: [
			{
				parent: "/marketing",
				name: "SEO",
				path: "/seo",
				title: "SEO",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/marketing",
				name: "Leads",
				path: "/leads",
				title: "Leads",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/marketing",
				name: "Customers",
				path: "/customers",
				title: "Customers",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/marketing",
				name: "Email list",
				path: "/email-list",
				title: "Email list",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/marketing",
				name: "Google Ads",
				path: "/google-ads",
				title: "Google Ads",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/marketing",
				name: "Facebook Ads",
				path: "/facebook-ads",
				title: "Facebook Ads",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/marketing",
				name: "Tiktok Ads",
				path: "/tiktok-ads",
				title: "Tiktok Ads",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Sales Channel",
		path: "/sales-channel",
		title: "Sales Channel",
		icon: <RiWechatChannelsLine size={24} />,
		tabItem: [
			{
				parent: "/sales-channel",
				name: "Online",
				path: "/online",
				title: "Online",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/sales-channel",
				name: "Offline",
				path: "/offline",
				title: "Offline",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/sales-channel",
				name: "Ecommerce",
				path: "/ecommerce",
				title: "Ecommerce",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Support",
		path: "/support",
		title: "Support",
		icon: <BiSupport size={24} />,
		tabItem: [
			{
				parent: "/support",
				name: "Tickets",
				path: "/tickets",
				title: "Tickets",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Settings",
		path: "/settings",
		title: "Settings",
		icon: <AiOutlineSetting size={24} />,
		tabItem: [
			{
				parent: "/settings",
				name: "User / User Group",
				path: "/usergroup",
				title: "User / User Group",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/settings",
				name: "Gerneral",
				path: "/gerneral",
				title: "Gerneral",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/settings",
				name: "Template",
				path: "/templates-web",
				title: "Template",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
];

export { ItemMenuLeft };
