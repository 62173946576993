import { Button, Collapse, Form, Input, Menu } from "antd";
import React, { useRef, useState } from "react";
import InfoCategory from "./TabItem/InfoCategory";
import { HiChevronDown } from "react-icons/hi";

export default function ModalCategory({ data, handleSave, handleCancel }) {
	const formRef = useRef(null);
	const [form] = Form.useForm();
	const [newData, setNewData] = useState(data);
	const onFinish = (values) => {
		// handleSave(
		// 	"dmnvt",
		// 	(data = {
		// 		ten_nvt: values?.ten_nvt,
		// 	})
		// );
		console.log({ ten_nvt: values?.ten_nvt });
		setNewData({
			...newData,
			ten_nvt: values?.ten_nvt,
		});
		handleCancel();
	};
	if (data) {
		form.setFieldsValue({
			ten_nvt: data?.ten_nvt,
		});
	}

	const formSections = [
		{
			id: "thongtin",
			title: "Thông tin",
			content: <InfoCategory />,
		},
		{
			id: "trichxuat",
			title: "Trích xuất",
			content: <InfoCategory />,
		},
		{
			id: "lichsutuvan",
			title: "Lịch sử tư vấn",
			content: <InfoCategory />,
		},
		{
			id: "thuvienanh",
			title: "Thư viện ảnh",
			content: <InfoCategory />,
		},
	];
	const scrollTo = (id) => {
		const el = document.getElementById(id);
		el.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	return (
		<Form
			ref={formRef}
			form={form}
			name="control-ref-category"
			onFinish={onFinish}
			style={{
				margin: "0 auto",
				// width: 400,
			}}
			labelCol={{ flex: "170px" }}
		>
			<div className="mdf_container">
				<Menu
					defaultSelectedKeys={[formSections[0].id]}
					mode="vertical"
					style={{ width: 200 }}
					items={formSections.map(({ id, title }) => ({
						key: id,
						label: title,
					}))}
					onClick={({ key }) => scrollTo(key)}
				/>
				<div className="mdf_content">
					{formSections.map(({ id, title, content }) => (
						<Collapse
							key={id}
							defaultActiveKey={["1"]}
							size="small"
							expandIcon={({ isActive }) => (
								<HiChevronDown
									style={{
										transition: "all 0.3s ease-in-out",
										transform: `rotate(${
											isActive ? 0 : -90
										}deg)`,
									}}
								/>
							)}
						>
							<Collapse.Panel header={title} key="1" id={id}>
								{content}
							</Collapse.Panel>
						</Collapse>
					))}
				</div>
			</div>
			<div className="flex justify-end items-center gap-4">
				<Button
					type="primary"
					htmlType="submit"
					className="btn_primary !px-3 !h-8"
					size="small"
				>
					Lưu
				</Button>
				<Button
					type="primary"
					htmlType="submit"
					className="btn_ternary !px-3 !h-8 !border-dashed"
					size="small"
				>
					Lưu & Thêm mới
				</Button>
			</div>
		</Form>
	);
}
