import React from "react";
import LineChart from "../components/chart/LineChart";
import "./homecms.scss";
import {
	LogoSMS,
	LogoSupport,
	LogoTelegram,
	LogoZalo,
} from "../../utils/images";

export default function HomeCMS() {
	return (
		<div className="flex justify-start items-center flex-wrap gap-4 py-3">
			<div className="w-[calc(50%-16px)]">
				<LineChart
					name={
						<p className="text-[#35d85e] text-xs font-semibold">
							Thống kê khách viếng thăm
						</p>
					}
				/>
			</div>
			<div className="w-[calc(50%-16px)]">
				<LineChart
					name={
						<p className="text-[#d89f35] text-xs font-semibold">
							Thống kê đơn hàng
						</p>
					}
				/>
			</div>
			<div className="w-[calc(50%-16px)]">
				<LineChart
					name={
						<p className="text-[#3f47b4] text-xs font-semibold">
							Top bài viết quan tâm
						</p>
					}
				/>
			</div>
			<div className="w-[calc(50%-16px)]">
				<LineChart
					name={
						<p className="text-[#49cbcf] text-xs font-semibold">
							Top Keyword
						</p>
					}
				/>
			</div>
			<div className="w-[calc(50%-16px)]">
				<div className="w-full h-full shadow-md p-3 text-xs font-semibold min-h-[160px]">
					<div className="text-[#a840ac]">
						<div>Tên đơn vị sử dụng : {"GOOD APP"}</div>
						<div>Người dùng đang đăng nhập : {"Thế Anh"}</div>
					</div>
					<br />
					<div className="text-[#2e3c8d]">
						<div>Gói dịch vụ WebTieuChuan : {"ten_goi_dv"}</div>
						<div>Ngày khởi tạo : {"10/10/2023"}</div>
						<div>Ngày hết hạn : {"10/10/2024"}</div>
						<div>Hợp đồng số : {"34243245345"}</div>
						<div>NVKD : {"..........."}</div>
					</div>
				</div>
			</div>
			<div className="w-[calc(50%-16px)]">
				<div className="w-full h-full shadow-md p-3 text-xs font-semibold min-h-[160px]">
					<div className="text-[#da3636]">
						Chăm sóc khách hàng : {"Công ty CP phần mềm CRM"}
					</div>
					<br />
					<div className="flex justify-between items-center w-full gap-4">
						<div className="w-[calc(25%-16px)]">
							<img
								className="w-3/5 cursor-pointer"
								src={LogoTelegram}
								alt="logo telegram"
							/>
						</div>
						<div className="w-[calc(25%-16px)]">
							<img
								className="w-3/5 cursor-pointer"
								src={LogoZalo}
								alt="logo zalo"
							/>
						</div>
						<div className="w-[calc(25%-16px)]">
							<img
								className="w-3/5 cursor-pointer"
								src={LogoSMS}
								alt="logo sms"
							/>
						</div>
						<div className="w-[calc(25%-16px)]">
							<img
								className="w-3/5 cursor-pointer"
								src={LogoSupport}
								alt="logo support"
							/>
						</div>
					</div>
				</div>
			</div>
			<p className="w-full text-[#326b97] text-xs font-bold text-center">
				Copyright WebTieuChuan.vn © 2023
			</p>
		</div>
	);
}
