import { Button, Form, Input, Select } from "antd";
import { Option } from "antd/es/mentions";
import React, { useRef } from "react";

export default function FormManagerMenu({
	dataToolbar,
	handleManegerWebPages,
	dataMenu,
}) {
	const formRef = useRef(null);
	const [form] = Form.useForm();
	const onFinish = (values) => {
		if (values.parent_page === undefined) {
			values.parent_page = "null";
		}
		console.log("Success:", values);
		handleManegerWebPages(values, dataMenu?._id);
	};
	if (dataMenu) {
		form.setFieldsValue({
			stt: dataMenu?.stt,
			title: dataMenu?.title,
			path: dataMenu?.path,
			parent_page:
				dataMenu?.parent_page === "null"
					? undefined
					: dataMenu?.parent_page,
		});
	}

	return (
		<div>
			<Form
				ref={formRef}
				form={form}
				name="control-ref"
				onFinish={onFinish}
				style={{
					margin: "0 auto",
					width: 400,
				}}
				labelCol={{ flex: "70px" }}
			>
				<Form.Item
					name="stt"
					label="Order"
					rules={[{ required: true }]}
				>
					<Input type="number" min={1} />
				</Form.Item>
				<Form.Item
					name="title"
					label="Title"
					rules={[{ required: true }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="path"
					label="Path"
					rules={[{ required: true }]}
				>
					<Input placeholder="/" />
				</Form.Item>
				<Form.Item
					name="parent_page"
					label="Parent"
					style={{
						fontSize: "14px",
					}}
				>
					<Select
						allowClear
						showSearch
						placeholder="Select a person"
						optionFilterProp="children"
					>
						{dataToolbar?.map((item, index) => (
							<Option key={index} value={item?.title}>
								{item?.title}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item className="flex m-0 pl-16">
					<Button
						type="primary"
						htmlType="submit"
						className="bg-[#326b97] hover:bg-[#09395e] hover:!text-white text-white font-medium !border-none !px-3"
						size="small"
					>
						Lưu
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
