import { Button, Input, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoSaveOutline } from "react-icons/io5";
import { request } from "../../../../utils/request";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
export default function UploadFile({
	type,
	data = {
		link: "",
		type: "image",
		exfields: {
			name: "image",
		},
	},
	isChooseImage,
	setIsChooseImage,
	messageApi,
	setLoading,
	handleGetUserFiles,
}) {
	const handleCancelChooseImage = () => {
		setIsChooseImage(false);
	};

	const [dataFile, setDataFile] = useState({});
	useEffect(() => {
		setDataFile(data);
	}, [data]);
	//upload image
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const handleCancel = () => setPreviewOpen(false);
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(data.link || file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};
	const handleChange = ({ fileList: newFileList }) =>
		setFileList(newFileList);
	const uploadButton = (
		<div className="w-full flex flex-col gap-2 justify-center items-center">
			<AiOutlinePlus />
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</div>
	);
	const handleSavePicture = async () => {
		try {
			setLoading(true);
			const formData = new FormData();
			formData.append("file", fileList[0].originFileObj);
			const response = await request.post(
				`api/uploadfile?json=1&folder=files`,
				formData,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			const imageUrl = response.data.fileUrl;
			if (type === "add") {
				await handleSaveUserFile(imageUrl);
			} else {
				handleEditUserFile(imageUrl);
			}
		} catch (error) {
			setLoading(false);
			setIsChooseImage(false);
			messageApi.open({
				type: "error",
				content: "Tạo thất bại",
			});
			console.log(error);
			//throw error;
		}
	};

	const handleSaveUserFile = async (file) => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					...dataFile,
					link: `https://api.goodapp.vn${file}`,
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			messageApi.open({
				type: "success",
				content: "Tạo thành công",
			});
			handleGetUserFiles();
			setDataFile({
				link: "",
				type: "image",
				exfields: {
					name: "image",
				},
			});
			setFileList([]);
			setIsChooseImage(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Tạo thất bại",
			});
			setIsChooseImage(false);
			console.log(error);
		}
	};

	const handleEditUserFile = async (file) => {
		try {
			await request.put(
				`api/${localStorage.getItem("id_app")}/userfiles/${
					dataFile._id
				}`,
				{
					...dataFile,
					link: `https://api.goodapp.vn${file}`,
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			messageApi.open({
				type: "success",
				content: "Thay đổi thành công",
			});
			handleGetUserFiles();
			setDataFile({
				link: "",
				type: "image",
				exfields: {
					name: "image",
				},
			});
			setFileList([]);
			setIsChooseImage(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Thay đổi thất bại",
			});
			setIsChooseImage(false);
			console.log(error);
		}
	};

	const beforeUpload = (file) => {
		return false;
	};

	return (
		<Modal
			title="Chọn hình ảnh"
			open={isChooseImage}
			footer={null}
			onCancel={handleCancelChooseImage}
			width={400}
		>
			<p className="w-fit mx-auto mb-2">
				<Upload
					beforeUpload={beforeUpload}
					listType="picture-card"
					fileList={fileList}
					onPreview={handlePreview}
					onChange={handleChange}
				>
					{fileList.length >= 1 ? null : uploadButton}
				</Upload>
			</p>
			<p className="w-2/3 mx-auto mb-2">
				<Input
					placeholder="Nhập tên hình ảnh"
					value={dataFile?.exfields?.name}
					onChange={(e) =>
						setDataFile({
							...dataFile,
							exfields: { name: e.target.value },
						})
					}
				/>
			</p>
			<p className="font-semibold flex justify-center items-center gap-3 !mt-5">
				<Button
					onClick={() => {
						fileList.length
							? handleSavePicture()
							: messageApi.open({
									type: "error",
									content: "Chọn hình ảnh",
							  });
					}}
					className="!border-[#09395e] hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1"
					size="small"
				>
					<IoSaveOutline /> Save
				</Button>
			</p>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img
					alt="example"
					style={{
						width: "100%",
					}}
					src={previewImage}
				/>
			</Modal>
		</Modal>
	);
}
