import { Popover } from "antd";
import React from "react";
import "./model.scss";

export default function PopoverModel({
	children,
	placement,
	content,
	title,
	arrow,
	trigger,
	zIndex,
	open,
	onOpenChange,
}) {
	return (
		<Popover
			placement={placement}
			content={content}
			title={title}
			arrow={arrow}
			trigger={trigger}
			zIndex={zIndex}
			open={open}
			onOpenChange={onOpenChange}
		>
			{children}
		</Popover>
	);
}
