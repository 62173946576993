import { Button } from "antd";
import { Parser } from "html-to-react";
import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { IoSaveOutline } from "react-icons/io5";
import EditorDraftWysiwyg from "../../../components/editorDraftWysiwyg/editorDraftWysiwyg";

export default function SectionContent5({ data, handleUpdateData }) {
	const [dataSection, setDataSection] = useState(data || {});
	const [openEditor, setOpenEditor] = useState(false);
	//editor content
	const handleGetHtml = (contentState, contentHtml) => {
		setDataSection({
			...dataSection,
			editor_html: contentState,
			content_html: contentHtml,
		});
	};
	useEffect(() => {
		handleUpdateData(dataSection);
	}, [dataSection]);
	return (
		<div className="relative">
			{!openEditor ? (
				<section className="w-full min-h-[400px] hover:bg-slate-100">
					{Parser().parse(
						data?.content_html || "<p>Text content</p>"
					)}
				</section>
			) : (
				<section className="w-full">
					<EditorDraftWysiwyg
						handleGetHtml={handleGetHtml}
						data={dataSection?.editor_html}
					/>
				</section>
			)}
			<div className="absolute top-24 right-3 z-50">
				<Button
					shape="circle"
					type="text"
					className="flex justify-center items-center"
					size="large"
				>
					{!openEditor ? (
						<BiEditAlt
							size={30}
							onClick={() => {
								setOpenEditor(true);
							}}
						/>
					) : (
						<IoSaveOutline
							size={30}
							onClick={() => {
								setOpenEditor(false);
							}}
						/>
					)}
				</Button>
			</div>
		</div>
	);
}
