import { Button } from "antd";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import SectionBanner from "./Sections/SectionBanner";
import SectionContent1 from "./Sections/SectionContent1";
import SectionContent2 from "./Sections/SectionContent2";
import SectionContent3 from "./Sections/SectionContent3";
import SectionContent4 from "./Sections/SectionContent4";
import SectionContent5 from "./Sections/SectionContent5";
import "./section.scss";

const ListSection = [
	{
		name: "Banner",
		component: <SectionBanner />,
	},
	{
		name: "Content 1",
		component: <SectionContent1 />,
	},
	{
		name: "Content 2",
		component: <SectionContent2 />,
	},
	{
		name: "Content 3",
		component: <SectionContent3 />,
	},
	{
		name: "Content 4",
		component: <SectionContent4 />,
	},
	{
		name: "Content 5",
		component: <SectionContent5 />,
	},
];

export default function TemplatePage({
	dataTemplatePage,
	setDataTemplatePage,
}) {
	const handleDeleteSection = (indexToDelete) => {
		const dataPageClone = [...dataTemplatePage];
		const newDataPageClone = dataPageClone.filter(
			(item, index) => index !== indexToDelete
		);
		setDataTemplatePage(newDataPageClone);
	};

	return (
		<div className="box_view_template_page flex flex-col w-full">
			{dataTemplatePage.map((item, index) => {
				const section = ListSection[item.id];
				const SectionComponent = section?.component;

				return (
					<div
						key={index}
						className="relative border border-white hover:border-[#09395e]"
					>
						{ListSection[item.id_section] &&
							React.cloneElement(
								ListSection[item.id_section].component,
								{
									data: item.exfields.data,
									handleUpdateData: (updatedData) => {
										const dataPageClone = [
											...dataTemplatePage,
										];
										dataPageClone[index].exfields.data =
											updatedData;
										setDataTemplatePage(dataPageClone);
									},
								}
							)}
						{/* {SectionComponent && (
							<SectionComponent
								data={item.data}
								handleUpdateData={(updatedData) => {
									const dataPageClone = [...dataPage];
									dataPageClone[index].data = updatedData;
									setDataTemplatePage(dataPageClone);
								}}
							/>
						)} */}
						<div className="absolute top-14 right-3 z-50">
							<Button
								shape="circle"
								type="text"
								className="flex justify-center items-center !bg-slate-200 hover:!bg-white"
								size="middle"
								onClick={() => handleDeleteSection(index)}
							>
								<AiOutlineDelete size={22} />
							</Button>
						</div>
					</div>
				);
			})}
		</div>
	);
}
