import { Button, Image, Modal, Spin, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { IoSaveOutline } from "react-icons/io5";
import { PlusOutlined } from "@ant-design/icons";
import { request } from "../../../utils/request";
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export default function ItemUploadFile({
	title,
	setData = () => {},
	data = {},
	handleGetPictureModal = () => {},
}) {
	const [dataImage, setDataImage] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isChooseImage, setIsChooseImage] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const handleSaveImage = () => {
		console.log();
		if (fileList[0]?.originFileObj !== undefined) {
			handleSavePicture();
		}
		// setIsChooseImage(false);
	};

	const handleGetUserFiles = async () => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
					params: {
						t: 1,
						page: 1,
						limit: 500,
					},
				}
			);
			setDataImage(response.data);
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		handleGetUserFiles();
	}, []);

	const handleSaveUserFile = async (file) => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					type: "image",
					link: `https://api.goodapp.vn${file}`,
					exfields: {
						name: "image",
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			messageApi.open({
				type: "success",
				content: "Thành công",
			});
			handleGetUserFiles();
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Thất bại",
			});
			console.log(error);
		}
	};

	//upload image
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const handleCancel = () => setPreviewOpen(false);
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};
	const handleChange = ({ fileList: newFileList }) =>
		setFileList(newFileList);
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</div>
	);
	const uploadImageApi = async (file) => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await request.post(
				`api/uploadfile?json=1&folder=files`,
				formData,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setLoading(false);
			setFileList([]);
			const imageUrl = response.data.fileUrl;
			return imageUrl;
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const handleSavePicture = async () => {
		try {
			const res = await uploadImageApi(fileList[0].originFileObj);
			await handleSaveUserFile(res);
			setData({
				...data,
				picture: `https://api.goodapp.vn${res}`,
			});
			handleGetPictureModal(`https://api.goodapp.vn${res}`);
			setDataImage([
				...dataImage,
				{
					link: `https://api.goodapp.vn${res}`,
				},
			]);
		} catch (error) {
			console.log(error);
		}
	};
	const beforeUpload = (file) => {
		return false;
	};

	return (
		<Spin spinning={loading}>
			<div className="flex flex-col justify-start items-start gap-3 mt-4">
				{contextHolder}
				{data?.picture ? (
					<Image
						width={96}
						height={96}
						className="rounded-lg object-cover"
						src={data?.picture}
					/>
				) : (
					<div
						onClick={() => setIsChooseImage(true)}
						className="w-24 h-24 rounded-lg bg-slate-50 border border-dashed flex flex-col justify-center items-center text-slate-500 hover:border-[#09395e] cursor-pointer"
					>
						<PlusOutlined />
						<div
							style={{
								marginTop: 8,
							}}
						>
							Upload
						</div>
					</div>
				)}
				<span className="text-[#326b97] text-xs uppercase text-left flex justify-start items-center gap-3">
					{title}
					{data?.picture && (
						<Button
							onClick={() => setIsChooseImage(true)}
							className="!border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
						>
							Chọn lại
							<CiEdit />
						</Button>
					)}
				</span>
				<Modal
					title="Thư viện hình ảnh"
					open={isChooseImage}
					footer={null}
					onCancel={() => setIsChooseImage(false)}
					style={{
						top: 20,
						"max-height": "90vh",
					}}
				>
					<div className="flex justify-start items-end gap-2 mt-3">
						<Upload
							beforeUpload={beforeUpload}
							listType="picture-card"
							fileList={fileList}
							onPreview={handlePreview}
							onChange={handleChange}
							className="!w-fit"
						>
							{fileList.length >= 1 ? null : uploadButton}
						</Upload>
						<Button
							onClick={handleSaveImage}
							className="m-2 !border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
						>
							{loading ? "Đang Lưu ..." : "Lưu Hình"}
							<IoSaveOutline />
						</Button>
					</div>
					<div className="py-3 text-[#326b97] text-base font-semibold text-left">
						Chọn hình để sử dụng
					</div>
					<div className="flex flex-wrap gap-3 h-[calc(100vh-400px)] min-h-[calc(100vh-400px)] overflow-y-auto">
						{dataImage?.map((item) => (
							<div
								onClick={() => {
									setData({
										...data,
										picture: item.link,
									});
									handleGetPictureModal(item.link);
									setIsChooseImage(false);
								}}
								className={`${
									data?.picture === item.link
										? "border-[#41ce4d] border-2"
										: ""
								} relative border w-24 h-24 rounded-lg hover:border-[#09395e] cursor-pointer`}
							>
								<Image
									width={96}
									height={96}
									className="rounded-lg object-contain bg-slate-50"
									src={item.link}
									preview={false}
								/>
								{data?.picture === item.link && (
									<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-5 h-5 rounded-full bg-white flex justify-center items-center">
										<FaCheck color="#41ce4d" />
									</div>
								)}
							</div>
						))}
					</div>
				</Modal>
				<Modal
					open={previewOpen}
					title={previewTitle}
					footer={null}
					onCancel={handleCancel}
				>
					<img
						alt="example"
						style={{
							width: "100%",
						}}
						src={previewImage}
					/>
				</Modal>
			</div>
		</Spin>
	);
}
