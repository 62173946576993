import { Button, Input, Popconfirm, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GrAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { request } from "../../../utils/request";
import TableManager from "../../components/table/tableManager";
import "./services.scss";
const { Search } = Input;

export default function Services() {
	const [dataServices, setDataServices] = useState([]);
	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const handleDeleteServices = async (id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/dmvt/${id}`,
				{
					params: {
						q: {
							is_service: true,
						},
					},
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
			handleGetDataServices();
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				description: error.message,
			});
		}
	};

	const columns = [
		{
			title: "Tên dịch vụ",
			dataIndex: "ten_vt",
			render: (title, record) => (
				<div className="flex flex-col field_title">
					<span>{title}</span>
					<div className="show_group_button">
						<span>
							<a
								href={`${localStorage.getItem(
									localStorage.getItem("id_app")
								)}services/${record?.exfields?.slug}`}
								target="_blank"
								rel="noreferrer"
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#326b97]"
							>
								View
							</a>
							<div
								onClick={() => {
									navigate(
										`/services/managerServices/${record?._id}`
									);
								}}
								className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div>
							<Popconfirm
								title={`Xoá "${record?.ten_vt}"`}
								onConfirm={() => {
									handleDeleteServices(record?._id);
								}}
								onCancel={() => {
									return false;
								}}
								okText="Yes"
								cancelText="No"
							>
								<div className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]">
									Delete
								</div>
							</Popconfirm>
						</span>
					</div>
				</div>
			),
			width: 200,
		},
		{
			title: "Hình ảnh",
			dataIndex: "picture",
			render: (picture) => {
				return (
					<div className="flex justify-center items-center w-28">
						{picture && (
							<img
								src={picture}
								alt="avatar"
								className="w-4/5 mx-auto bg-slate-100 object-cover"
							/>
						)}
					</div>
				);
			},
			width: 150,
		},
		{
			title: "Giá bán lẻ",
			dataIndex: "gia_ban_le",
			render: (gia_ban_le) => (
				<div className="flex flex-col">
					<span>
						{new Intl.NumberFormat("vi-VN").format(gia_ban_le) +
							" VND"}
					</span>
				</div>
			),
			width: 200,
		},
		{
			title: "Người tạo",
			dataIndex: "name_user_created",
			width: 150,
		},
		{
			title: "Tags",
			key: "exfields",
			dataIndex: "exfields",
			render: (_, record) => (
				<>
					{record?.exfields?.tags?.map((tag) => {
						let color = tag.length > 5 ? "geekblue" : "green";
						return (
							<Tag color={color} key={tag}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
			width: 200,
		},
		{
			title: "Ngày tạo",
			dataIndex: "date_created",
			width: 200,
			render: (date_created, record) => {
				return (
					<div className="flex flex-col">
						<span>
							{record?.exfields?.status_post === 1
								? "Publish"
								: "Pending"}
						</span>
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];
	const handleGetDataServices = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem(
					"id_app"
				)}/dmvt/?limit=500&access_token=flex.public.token`,
				{
					params: {
						q: {
							is_service: true,
						},
					},
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "application/json",
					},
				}
			);
			setDataServices(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			//throw error;
		}
	};
	useEffect(() => {
		handleGetDataServices();
	}, []);

	const navigate = useNavigate();
	const onSearch = (value, _e, info) => console.log(info?.source, value);
	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	return (
		<React.Fragment>
			{contextHolder}
			<div className="h-12 flex justify-between items-center pt-4">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Bài viết
				</div>
				<div className="flex justify-end gap-5 items-center box_menu_post">
					<div className="flex justify-end items-center gap-3">
						{/* <Button
							onClick={() => {
								navigate("/categories");
							}}
							className="!border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
						>
							Loại dịch vụ
						</Button> */}
					</div>
					<Button
						onClick={() => {
							navigate("/services/managerServices/create");
						}}
						className="!border-[#09395e] hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1"
					>
						<GrAdd /> Thêm mới
					</Button>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex justify-between items-center pt-4">
					<div>
						<Search
							placeholder="Từ khoá ..."
							allowClear
							onSearch={onSearch}
							style={{
								width: 300,
							}}
						/>
					</div>
				</div>
				<div className="box_content">
					<TableManager
						data={dataServices}
						columns={columns}
						loading={loading}
						scroll={{ x: 900, y: "calc(100vh - 332px)" }}
						className="border-b-[1px] border-slate-400"
					/>
				</div>
			</div>
		</React.Fragment>
	);
}
