import { Button, Checkbox, Input, Tabs, Tag, message } from "antd";
import React from "react";
import { GrAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import TableManager from "../../components/table/tableManager";
import { useState } from "react";
import { request } from "../../../utils/request";
import { useEffect } from "react";
import moment from "moment";
import PopupModel from "../../components/modal/popupModel";
import ListPage from "./TemplatePage/ListPage/ListPage";
import ListSection from "./TemplatePage/ListSection/ListSection";
const { Search } = Input;

export default function Pages() {
	const [dataPage, setDataPage] = useState([]);
	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const navigate = useNavigate();
	const onSearch = (value, _e, info) => console.log(info?.source, value);
	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	// Data Template
	const [dataTemplates, setDataTemplates] = useState([]);
	const handleGetDataTemplates = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/webtemplate`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataTemplates(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			//throw error;
		}
	};

	// Data Section
	const [dataSections, setDataSections] = useState([]);
	const handleGetDataSections = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/websection`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataSections(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			//throw error;
		}
	};
	//

	const handleGetDataPage = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem(
					"id_app"
				)}/webpagedetails/?limit=500&access_token=flex.public.token`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataPage(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			//throw error;
		}
	};
	useEffect(() => {
		handleGetDataPage();
		handleGetDataSections();
		handleGetDataTemplates();
	}, []);

	const handleDeletePage = async (id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/webpagedetails/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
			handleGetDataPage();
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				description: error.message,
			});
			//throw error;
		}
	};

	const columns = [
		{
			title: "Tiêu đề",
			dataIndex: "title",
			// fixed: "left",
			render: (title, record) => (
				<div className="flex flex-col field_title">
					<span>{title}</span>
					<div className="show_group_button">
						<span>
							<a
								href={`${localStorage.getItem(
									localStorage.getItem("id_app")
								)}${record?.exfields?.url}`}
								target="_blank"
								rel="noreferrer"
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#326b97]"
							>
								View
							</a>
							<div
								onClick={() => {
									navigate(
										`/pages/managerPage/edit?page=${record?._id}`
									);
								}}
								className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div>
							<div
								onClick={() => handleDeletePage(record?._id)}
								className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]"
							>
								Delete
							</div>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Tác giả",
			dataIndex: "name_user_created",
		},
		{
			title: "Từ khoá SEO",
			key: "exfields",
			dataIndex: "exfields",
			render: (_, record) => (
				<>
					{record?.exfields?.keywords_seo?.map((keywords) => {
						return (
							<Tag color={"geekblue"} key={keywords}>
								{keywords.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
		},
		{
			title: "Ngày tạo",
			dataIndex: "date_created",
			width: 200,
			render: (date_created, record) => {
				return (
					<div className="flex flex-col">
						<span>
							{record?.exfields?.status_post === 1
								? "Publish"
								: "Pending"}
						</span>
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];

	const TabContent = [
		{
			id: 1,
			label: "Đã duyệt",
			children: (
				<TableManager
					data={dataPage}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
		{
			id: 2,
			label: "Đang chờ duyệt",
			children: (
				<TableManager
					data={dataPage}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
		{
			id: 3,
			label: "Nỗi bật",
			children: (
				<TableManager
					data={dataPage}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
		{
			id: 4,
			label: "Từ chối",
			children: (
				<TableManager
					data={dataPage}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
	];

	const [openListPage, setOpenListPage] = useState(false);
	const handleCancelListPage = () => {
		setOpenListPage(false);
	};

	const [openListSection, setOpenListSection] = useState(false);
	const handleOpenListSection = () => {
		setOpenListSection(true);
	};
	const handleCancelListSection = () => {
		setOpenListSection(false);
	};

	return (
		<React.Fragment>
			{contextHolder}
			<div className="h-12 flex justify-between items-center pt-4">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Pages
				</div>
				<div className="flex justify-end gap-5 items-center box_menu_post">
					{/* <div className="flex justify-end items-center gap-3">
						<Button
							onClick={() => {
								navigate("/tags");
							}}
							className="!border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
						>
							Tags
						</Button>
					</div> */}
					<Button
						onClick={() => {
							// navigate("/pages/managerPage/create");
							setOpenListPage(true);
						}}
						className="!border-[#09395e] hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1"
					>
						<GrAdd /> Thêm mới
					</Button>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex justify-between items-center pt-4">
					<div>
						<Search
							placeholder="Từ khoá ..."
							allowClear
							onSearch={onSearch}
							style={{
								width: 300,
							}}
						/>
					</div>
					<div className="flex justify-end items-end gap-3 box_menu_post">
						<Checkbox onChange={onChange}>Show all tags</Checkbox>
					</div>
				</div>
				<div className="box_content">
					{/* <Tabs
						defaultActiveKey="1"
						size={"middle"}
						items={TabContent.map((item) => {
							return {
								label: (
									<p className="text-[#326b97] hover:text-[#09395e]">
										{item.label}
									</p>
								),
								key: item.id,
								children: item.children,
							};
						})}
						className="!mb-0 border-b-[1px] border-slate-400"
					/> */}
					<TableManager
						data={dataPage}
						columns={columns}
						loading={loading}
						scroll={{ y: "calc(100vh - 332px)" }}
						className="border-b-[1px] border-slate-400"
					/>
				</div>
			</div>
			<PopupModel
				title={"Chọn giao diện cho Page"}
				style={{
					top: "30px",
				}}
				open={openListPage}
				onCancel={handleCancelListPage}
				width={"70vw"}
				footer={null}
			>
				<ListPage
					handleOpenListSection={handleOpenListSection}
					handleCancelListPage={handleCancelListPage}
					data={dataTemplates}
				/>
			</PopupModel>
			<PopupModel
				title={"Chọn section tạo Page"}
				style={{
					top: "30px",
				}}
				open={openListSection}
				onCancel={handleCancelListSection}
				width={"70vw"}
				footer={null}
			>
				<ListSection
					type={"page"}
					dataSections={dataSections}
					messageApi={messageApi}
					setLoading={setLoading}
					close={handleCancelListSection}
				/>
			</PopupModel>
		</React.Fragment>
	);
}
