import { Button, Input, Popconfirm, Tabs, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GrAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { request } from "../../../utils/request";
import PopupModel from "../../components/modal/popupModel";
import TableManager from "../../components/table/tableManager";
import ModalCategory from "./Modal/ModalCategory";
import ModalProduct from "./Modal/ModalProduct";
import "./products.scss";
const { Search } = Input;

export default function Products() {
	const [openModal, setOpenModal] = useState(false);
	const [dataProduct, setDataProduct] = useState([]);
	const [dataCategory, setDataCategory] = useState([]);

	const [dataModal, setDataModal] = useState({});

	const handelOpenModalProduct = () => {
		setOpenModal("product");
	};
	const handelOpenModalCategory = () => {
		setOpenModal("category");
	};

	const handleGetData = async (api, q) => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/${api}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
					params: {
						q: { ...q },
					},
				}
			);
			return response?.data;
		} catch (error) {
			console.log(error);
		}
	};
	const handleSaveData = async (api, data) => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/${api}`,
				data,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			const newData = await handleGetData(api, {
				status: true,
			});
			if (api === "dmvt") {
				setDataProduct(newData);
			}
			if (api === "dmnvt") {
				setDataCategory(newData);
			}
			messageApi.open({
				type: "success",
				content: "Tạo thành công",
			});
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Tạo thất bại",
			});
		}
	};
	const handleDeleteData = async (api, id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/${api}/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			const newData = await handleGetData(api, {
				status: true,
			});
			if (api === "dmvt") {
				setDataProduct(newData);
			}
			if (api === "dmnvt") {
				setDataCategory(newData);
			}
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
		}
	};

	const handleGetDataAll = async () => {
		try {
			const newDataProduct = await handleGetData("dmvt", {
				status: true,
			});
			const newDataCategory = await handleGetData("dmnvt", {
				status: true,
			});
			setDataProduct(newDataProduct);
			setDataCategory(newDataCategory);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		handleGetDataAll();
	}, []);

	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const columnsProduct = [
		{
			title: "Tên vật tư",
			dataIndex: "ten_vt",
			// fixed: "left",
			render: (ten_vt, record) => (
				<div className="flex flex-col field_title">
					<span>{ten_vt}</span>
					<div className="show_group_button">
						<span>
							<div
								onClick={() => {
									setDataModal(record);
									setOpenModal("product");
								}}
								className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div>
							<Popconfirm
								title={`Xoá ${record?.ten_vt}`}
								onConfirm={() => {
									handleDeleteData("dmvt", record?._id);
								}}
								onCancel={() => {
									return false;
								}}
								okText="Yes"
								cancelText="No"
							>
								<div className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]">
									Delete
								</div>
							</Popconfirm>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Mã vật tư",
			dataIndex: "ma_vt",
		},
		{
			title: "ĐVT",
			dataIndex: "ten_dvt",
		},
		{
			title: "Nhóm",
			dataIndex: "ten_nvt",
		},
		{
			title: "Giá bán lẻ",
			dataIndex: "prices",
			render: (prices) => (
				<>
					{" "}
					{prices?.gia_ban_le?.toLocaleString("it-IT", {
						style: "currency",
						currency: "VND",
					})}{" "}
				</>
			),
		},
		{
			title: "Trạng thái",
			key: "status",
			dataIndex: "status",
			render: (status, record) => {
				return (
					<>
						{status ? (
							<Tag color={"green"} key={status}>
								Sử dụng
							</Tag>
						) : (
							<Tag color={"red"} key={status}>
								Không sử dụng
							</Tag>
						)}
					</>
				);
			},
		},
		{
			title: "Date",
			dataIndex: "date_created",
			width: 200,
			render: (date_created, record) => {
				return (
					<div className="flex flex-col">
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];
	const columnsCategory = [
		{
			title: "Tên nhóm",
			dataIndex: "ten_nvt",
			// fixed: "left",
			render: (ten_nvt, record) => (
				<div className="flex flex-col field_title">
					<span>{ten_nvt}</span>
					<div className="show_group_button">
						<span>
							<div
								onClick={() => {
									setDataModal(record);
									setOpenModal("category");
								}}
								className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div>
							<Popconfirm
								title={`Xoá ${record?.ten_nvt}`}
								onConfirm={() => {
									handleDeleteData("dmnvt", record?._id);
								}}
								onCancel={() => {
									return false;
								}}
								okText="Yes"
								cancelText="No"
							>
								<div className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]">
									Delete
								</div>
							</Popconfirm>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Người tạo",
			dataIndex: "name_user_created",
		},
		{
			title: "Trạng thái",
			key: "status",
			dataIndex: "status",
			render: (status, record) => {
				return (
					<>
						{status ? (
							<Tag color={"green"} key={status}>
								Sử dụng
							</Tag>
						) : (
							<Tag color={"red"} key={status}>
								Không sử dụng
							</Tag>
						)}
					</>
				);
			},
		},
		{
			title: "Ngày tạo",
			dataIndex: "date_created",
			width: 200,
			render: (date_created, record) => {
				return (
					<div className="flex flex-col">
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];

	const navigate = useNavigate();
	const onSearch = (value, _e, info) => console.log(info?.source, value);
	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};
	const TabContent = [
		{
			id: 1,
			label: "Products",
			children: (
				<TableManager
					data={dataProduct}
					columns={columnsProduct}
					loading={loading}
					scroll={{ y: "calc(100vh - 332px)" }}
				/>
			),
		},
		{
			id: 2,
			label: "Category",
			children: (
				<TableManager
					data={dataCategory}
					columns={columnsCategory}
					loading={loading}
					scroll={{ y: "calc(100vh - 332px)" }}
				/>
			),
		},
	];
	return (
		<React.Fragment>
			{contextHolder}
			<div className="h-12 flex justify-between items-center pt-4">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Products
				</div>
				<div className="flex justify-end gap-5 items-center box_menu_post">
					<Button
						onClick={() => {
							handelOpenModalCategory();
							setDataModal({});
						}}
						className="!border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
					>
						<GrAdd /> Category
					</Button>
					<Button
						onClick={() => {
							handelOpenModalProduct();
							setDataModal({});
						}}
						className="!border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
					>
						<GrAdd /> Product
					</Button>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex justify-between items-center pt-4">
					<div>
						<Search
							placeholder="Từ khoá ..."
							allowClear
							onSearch={onSearch}
							style={{
								width: 300,
							}}
						/>
					</div>
				</div>
				<div className="box_content">
					<Tabs
						defaultActiveKey="1"
						size={"middle"}
						items={TabContent.map((item) => {
							return {
								label: (
									<p className="text-[#326b97] hover:text-[#09395e]">
										{item.label}
									</p>
								),
								key: item.id,
								children: item.children,
							};
						})}
						className="!mb-0 border-b-[1px] border-slate-400"
					/>
				</div>
			</div>
			<PopupModel
				title={`Thêm ${
					openModal === "category" ? "Nhóm sản phẩm" : "Sản phẩm"
				}`}
				open={openModal ? true : false}
				onCancel={() => {
					setOpenModal(false);
				}}
				footer={null}
				width={"95vw"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
			>
				<div>
					{openModal === "category" && (
						<ModalCategory
							data={dataModal}
							handleSave={handleSaveData}
							handleCancel={() => {
								setOpenModal(false);
							}}
						/>
					)}
					{openModal === "product" && (
						<ModalProduct
							data={dataModal}
							handleSave={handleSaveData}
							handleCancel={() => {
								setOpenModal(false);
							}}
						/>
					)}
				</div>
			</PopupModel>
		</React.Fragment>
	);
}
