import React from "react";
import { PiPlusLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

export default function ListPage({
	handleOpenListSection,
	handleCancelListPage,
	data,
}) {
	const navigate = useNavigate();
	const handeOpenChooseSection = () => {
		handleCancelListPage();
		handleOpenListSection();
	};

	return (
		<div className="flex justify-start items-center flex-wrap gap-2 py-3 max-h-[80vh] overflow-auto">
			{data.map((item) => (
				<div
					key={item.id}
					onClick={() => {
						navigate(
							`/pages/managerPage/create?template=${item._id}`
						);
					}}
					className="flex flex-col gap-2 items-center w-[calc(25%-8px)] min-w-[200px] h-44 bg-slate-100 cursor-pointer p-2 rounded-lg border border-white hover:border-[#09395e]"
				>
					<div className="w-full h-4/5">
						<img
							src={
								item?.exfields?.image ||
								"https://api.goodapp.vn/getfile/files/6498312796f391dad61d71d7_1697530638530_banner.png.webp"
							}
							alt={item.title}
							className="w-full h-full object-contain"
						/>
					</div>
					<p>{item.title}</p>
				</div>
			))}
			<div
				onClick={handeOpenChooseSection}
				className="flex flex-col gap-2 items-center w-[calc(25%-8px)] min-w-[200px] h-44 bg-slate-100 cursor-pointer p-2 rounded-lg border border-dotted border-white hover:border-[#09395e]"
			>
				<div className="w-full h-4/5 flex justify-center items-center">
					<PiPlusLight size={70} />
				</div>
				<p>Tạo page mới</p>
			</div>
		</div>
	);
}
