import React from "react";
import LeftMenu from "../menu/leftMenu";
import Header from "../header/Header";
import IdApp from "./IdApp";

function LayoutDefault({ children, name }) {
	return (
		<IdApp>
			<div className="flex justify-end">
				<div
					id="left_menu"
					className="w-[80px] transition-all fixed z-20 left-0 h-full"
				>
					<LeftMenu />
				</div>
				<div
					id="body_content"
					className="w-[calc(100%-80px)] transition-all"
				>
					<div className="flex flex-col bg-[whitesmoke]">
						<Header />
						<div
							component="main"
							flexGrow={1}
							minWidth={360}
							className="pt-12 bg-[#fafafa] h-screen px-[28px]"
						>
							{children}
						</div>
					</div>
				</div>
			</div>
		</IdApp>
	);
}

export default LayoutDefault;
