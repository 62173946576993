import { Button, Dropdown } from "antd";
import React from "react";
import { CgMenuCheese } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

export default function Header() {
	const navigate = useNavigate();
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("id_app");
		localStorage.removeItem("name_app");
		navigate("/sign-in");
	};
	const items = [
		{
			key: "1",
			label: (
				<div
					onClick={() => {
						navigate("/app");
					}}
				>
					Cấu hình công ty
				</div>
			),
		},
		{
			key: "2",
			label: <div onClick={handleLogout}>Đăng xuất</div>,
		},
	];

	const handleMenu = () => {
		const left_menu = document.getElementById("left_menu");
		const body_content = document.getElementById("body_content");
		const header_content = document.getElementById("header_content");
		const left_menu_full = document.getElementById("left_menu_full");
		const left_menu_mini = document.getElementById("left_menu_mini");
		const table_user = document.getElementById("table_user");
		const table_rank = document.getElementById("table_rank");

		if (left_menu?.classList.contains("w-[80px]")) {
			left_menu?.classList.remove("w-[80px]");
			left_menu?.classList.add("w-[192px]");
			body_content?.classList.remove("w-[calc(100%-80px)]");
			body_content?.classList.add("w-[calc(100%-192px)]");
			body_content?.classList.add("transition-all");
			header_content?.classList.remove("w-[calc(100%-80px)]");
			header_content?.classList.add("w-[calc(100%-192px)]");
			left_menu_full?.classList.remove("hidden");
			left_menu_mini?.classList.add("hidden");
			table_user?.classList.remove("w-[65%]");
			table_user?.classList.add("w-[75%]");
			table_rank?.classList.remove("w-[30%]");
			table_rank?.classList.add("w-[25%]");
		} else {
			left_menu?.classList.remove("w-[192px]");
			left_menu?.classList.add("w-[80px]");
			body_content?.classList.remove("w-[calc(100%-192px)]");
			body_content?.classList.add("w-[calc(100%-80px)]");
			header_content?.classList.remove("w-[calc(100%-192px)]");
			header_content?.classList.add("w-[calc(100%-80px)]");
			left_menu_full?.classList.add("hidden");
			left_menu_mini?.classList.remove("hidden");
			table_user?.classList.remove("w-[75%]");
			table_user?.classList.add("w-[65%]");
			table_rank?.classList.remove("w-[25%]");
			table_rank?.classList.add("w-[30%]");
		}
	};

	const getNameUser = () => {
		const current_user = JSON.parse(localStorage.getItem("current_user"));
		return current_user?.name;
	};

	const getNameCompany = () => {
		const current_company = localStorage.getItem("name_app");
		return current_company;
	};

	return (
		<div
			id="header_content"
			className="fixed h-12 bg-white border-b-[1px] flex-col transition-all z-10 w-[calc(100%-80px)]"
		>
			<div className="h-full flex justify-between items-center px-2">
				<div className="flex items-center">
					<div className="pl-5 flex items-center">
						<div>Công ty hiện tại: {getNameCompany()}</div>
					</div>
					{/* <Button
						className="mx-[8px!important] border-none flex !text-cyan-900 justify-center items-center hover:bg-slate-200"
						onClick={() => {
							handleMenu();
						}}
						shape="circle"
					>
						<CgMenuCheese size={26} />
					</Button> */}
				</div>
				<div className="flex space-x-5 pr-2">
					<div className="flex items-center cursor-pointer">
						<div>{getNameUser()}</div>
					</div>
					<div className="flex items-center">
						<Dropdown
							menu={{
								items,
							}}
							placement="bottomLeft"
						>
							<Button
								className="mx-[8px!important] border-none flex !text-cyan-900 justify-center items-center hover:bg-slate-200"
								shape="circle"
							>
								<img
									src={
										"https://api.fostech.vn/images/avatar.jpg"
									}
									alt="avatar"
									className="w-8 h-8 rounded-full"
								/>
							</Button>
						</Dropdown>
					</div>
				</div>
			</div>
		</div>
	);
}
