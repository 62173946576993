import { Button, Input, Tabs, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GrAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { request } from "../../utils/request";
import PopupModel from "../components/modal/popupModel";
import TableManager from "../components/table/tableManager";
import ListSection from "../content/pages/TemplatePage/ListSection/ListSection";
const { Search } = Input;

export default function TemplateWeb() {
	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const navigate = useNavigate();
	const onSearch = (value, _e, info) => console.log(info?.source, value);

	useEffect(() => {
		handleGetDataTemplates();
		handleGetDataSections();
	}, []);

	// Data Template
	const [dataTemplates, setDataTemplates] = useState([]);
	const handleGetDataTemplates = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/webtemplate`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataTemplates(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	const handleDeleteTemplate = async (id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/webtemplate/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
			handleGetDataTemplates();
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				description: error.message,
			});
			//throw error;
		}
	};
	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			// fixed: "left",
			render: (title, record) => (
				<div className="flex flex-col field_title">
					<span>{title}</span>
					<div className="show_group_button">
						<span>
							{/* <a
								href={`https://vanhuong.vercel.app/${record?.exfields?.url}`}
								target="_blank"
								rel="noreferrer"
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#326b97]"
							>
								View
							</a> */}
							<div
								onClick={() => {
									navigate(
										`/templates-web/managerTemplate/edit?template=${record?._id}`
									);
								}}
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div>
							<div
								onClick={() =>
									handleDeleteTemplate(record?._id)
								}
								className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]"
							>
								Delete
							</div>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Author",
			dataIndex: "name_user_created",
		},
		{
			title: "Status",
			key: "exfields",
			dataIndex: "exfields",
			render: (_, record) => (
				<Tag
					color={`${
						record?.exfields?.status_template === 0
							? "green"
							: "red"
					}`}
				>
					<span>
						{record?.exfields?.status_template === 0
							? "Publish"
							: "Pending"}
					</span>
				</Tag>
			),
		},
		{
			title: "Date",
			dataIndex: "date_created",
			width: 200,
			render: (date_created) => {
				return (
					<div className="flex flex-col">
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];
	//

	// Data Section
	const [dataSections, setDataSections] = useState([]);
	const handleGetDataSections = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/websection`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataSections(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	const handleDeleteSections = async (id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/websection/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
			handleGetDataTemplates();
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				description: error.message,
			});
			//throw error;
		}
	};
	const columnsSections = [
		{
			title: "Title",
			dataIndex: "title",
			// fixed: "left",
			render: (title, record) => (
				<div className="flex flex-col field_title">
					<span>{title}</span>
					<div className="show_group_button">
						<span>
							{/* <a
								href={`https://vanhuong.vercel.app/${record?.exfields?.url}`}
								target="_blank"
								rel="noreferrer"
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#326b97]"
							>
								View
							</a>
							<div
								onClick={() => {
									navigate(
										`/pages/managerPage/${record?._id}`
									);
								}}
								className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div> */}
							<div
								onClick={() =>
									handleDeleteSections(record?._id)
								}
								className="cursor-pointer hover:underline text-[#000] hover:text-[#973232]"
							>
								Delete
							</div>
						</span>
					</div>
				</div>
			),
		},
		{
			title: "Author",
			dataIndex: "name_user_created",
		},
		{
			title: "Status",
			key: "exfields",
			dataIndex: "exfields",
			render: (_, record) => (
				<Tag
					color={`${
						record?.exfields?.status_section === 0 ? "green" : "red"
					}`}
				>
					<span>
						{record?.exfields?.status_section === 0
							? "Publish"
							: "Pending"}
					</span>
				</Tag>
			),
		},
		{
			title: "Date",
			dataIndex: "date_created",
			width: 200,
			render: (date_created) => {
				return (
					<div className="flex flex-col">
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];
	//

	const TabContent = [
		{
			id: 1,
			label: "Templates",
			children: (
				<TableManager
					data={dataTemplates}
					columns={columns}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
		{
			id: 2,
			label: "Sections",
			children: (
				<TableManager
					data={dataSections}
					columns={columnsSections}
					loading={loading}
					scroll={{ x: 1100, y: "calc(100vh - 332px)" }}
				/>
			),
		},
	];

	const [openListSection, setOpenListSection] = useState(false);
	const handleCancelListSection = () => {
		setOpenListSection(false);
	};
	return (
		<React.Fragment>
			{contextHolder}
			<div className="h-12 flex justify-between items-center pt-4">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Templates
				</div>
				<div className="flex justify-end gap-5 items-center box_menu_post">
					<Button
						onClick={() => {
							setOpenListSection(true);
						}}
						className="!border-[#09395e] hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1"
					>
						<GrAdd /> Add
					</Button>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex justify-between items-center pt-4">
					<div>
						<Search
							placeholder="Từ khoá ..."
							allowClear
							onSearch={onSearch}
							style={{
								width: 300,
							}}
						/>
					</div>
				</div>
				<div className="box_content">
					<Tabs
						defaultActiveKey="1"
						size={"middle"}
						items={TabContent.map((item) => {
							return {
								label: (
									<p className="text-[#326b97] hover:text-[#09395e]">
										{item.label}
									</p>
								),
								key: item.id,
								children: item.children,
							};
						})}
						className="!mb-0 border-b-[1px] border-slate-400"
					/>
				</div>
			</div>
			<PopupModel
				title={"Chọn section tạo Page"}
				style={{
					top: "30px",
				}}
				open={openListSection}
				onCancel={handleCancelListSection}
				width={"70vw"}
				footer={null}
			>
				<ListSection
					type={"template"}
					dataSections={dataSections}
					messageApi={messageApi}
					setLoading={setLoading}
					close={handleCancelListSection}
				/>
			</PopupModel>
		</React.Fragment>
	);
}
