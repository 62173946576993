import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { request } from "../../../utils/request";
import "./editorDraftWysiwyg.scss";
import draftToHtml from "draftjs-to-html";

function EditorDraftWysiwyg({ handleGetHtml, data, style }) {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	useEffect(() => {
		if (data) {
			const contentState = convertFromRaw(JSON.parse(data));
			const newEditorState = EditorState.createWithContent(contentState);
			setEditorState(newEditorState);
		}
	}, [data]);

	const handleEditorChange = (editorState) => {
		setEditorState(editorState);
	};
	const handleEditorBlur = () => {
		const contentState = JSON.stringify(
			convertToRaw(editorState.getCurrentContent())
		);

		const contentHtml = draftToHtml(
			convertToRaw(
				EditorState.createWithContent(
					convertFromRaw(JSON.parse(contentState))
				).getCurrentContent()
			)
		);
		handleGetHtml(contentState, contentHtml);
	};

	const uploadImageApi = async (file) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await request.post(
				`api/uploadfile?json=1&folder=files`,
				formData,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);

			const imageUrl = response.data.fileUrl;
			// await handleSaveUserFile(imageUrl);
			return imageUrl;
		} catch (error) {
			console.log(error);
		}
	};

	const handleSaveUserFile = async (file) => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					type: "image",
					exfields: {
						name: "image",
					},
					link: `https://api.goodapp.vn${file}`,
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const uploadImageCallback = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new window.FileReader();

			reader.onloadend = async () => {
				try {
					const res = await uploadImageApi(file);
					console.log(res);
					resolve({
						data: { link: "https://api.goodapp.vn" + res },
					});
				} catch (error) {
					console.error("Lỗi khi tải lên ảnh:", error);
					alert("Có lỗi xảy ra khi tải lên ảnh.");
					reject(error);
				}
			};

			reader.readAsDataURL(file);
		});
	};

	const toolbarOptions = {
		options: [
			"inline",
			"blockType",
			"list",
			"textAlign",
			"colorPicker",
			"fontSize",
			"fontFamily",
			"link",
			"embedded",
			"emoji",
			"image",
			"history",
			"remove",
		],
		inline: {
			inDropdown: false,
			options: [
				"bold",
				"italic",
				"underline",
				"strikethrough",
				"monospace",
				"superscript",
				"subscript",
			],
		},
		blockType: {
			inDropdown: true,
			options: [
				"Normal",
				"H1",
				"H2",
				"H3",
				"H4",
				"H5",
				"H6",
				"Blockquote",
				"Code",
			],
		},
		list: {
			inDropdown: true,
			options: ["unordered", "ordered", "indent", "outdent"],
		},
		textAlign: {
			inDropdown: true,
			options: ["left", "center", "right", "justify"],
		},
		link: {
			inDropdown: false,
		},
		embedded: {
			inDropdown: false,
		},
		emoji: {
			inDropdown: false,
		},
		image: {
			urlEnabled: true,
			uploadEnabled: true,
			alignmentEnabled: true,
			uploadCallback: uploadImageCallback, // Hàm xử lý upload ảnh
			previewImage: true,
			inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
			alt: {
				present: true,
				mandatory: false,
			},
		},
		colorPicker: {
			// Thêm cấu hình cho color picker
			colors: [
				"rgb(97,189,109)",
				"rgb(26,188,156)",
				"rgb(84,172,210)",
				"rgb(44,130,201)",
				"rgb(147,101,184)",
				"rgb(71,85,119)",
				"rgb(204,204,204)",
				"rgb(65,168,95)",
				"rgb(0,168,133)",
				"rgb(61,142,185)",
				"rgb(41,105,176)",
				"rgb(85,57,130)",
				"rgb(40,50,78)",
				"rgb(0,0,0)",
				"rgb(247,218,100)",
				"rgb(251,160,38)",
				"rgb(235,107,86)",
				"rgb(226,80,65)",
				"rgb(163,143,132)",
				"rgb(239,239,239)",
				"rgb(255,255,255)",
				"rgb(250,197,28)",
				"rgb(243,121,52)",
				"rgb(209,72,65)",
				"rgb(184,49,47)",
				"rgb(124,112,107)",
				"rgb(209,213,216)",
			],
		},
	};

	return (
		<div className="box_editor_draft" style={style}>
			<Editor
				editorState={editorState}
				onEditorStateChange={handleEditorChange}
				toolbar={toolbarOptions}
				onBlur={handleEditorBlur} // Thay vì onChange
			/>
		</div>
	);
}

export default EditorDraftWysiwyg;
