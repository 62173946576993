import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import { IoAdd } from "react-icons/io5";
import PopoverModel from "../../../components/modal/popoverModel";
import UploadImage from "../../components/uploadImage";
import "../section.scss";

export default function SectionContent4({ data, handleUpdateData }) {
	const [dataSection, setDataSection] = useState(data || {});

	//upload image
	const [isChooseImage, setIsChooseImage] = useState(-1);
	const handleChooseImage = (index) => {
		setIsChooseImage(index);
	};
	const handleCancelChooseImage = () => {
		setIsChooseImage(false);
	};

	const handleDeleteCard = (id) => {
		setDataSection({
			...dataSection,
			listCard: dataSection.listCard.filter((item) => item.id !== id),
		});
	};
	const handleAddCard = () => {
		const lastItem = dataSection.listCard[dataSection.listCard.length - 1];
		const newId = lastItem ? lastItem.id + 1 : 1; // Kiểm tra mảng trống rỗng
		const newItem = {
			id: newId,
			// ThêmCAC trường dữ liệu khác cho phần tử mới ở đây
			listContent: ["Nội dung 1", "Nội dung 2", "Nội dung 3"],
		};

		setDataSection({
			...dataSection,
			listCard: [...dataSection.listCard, newItem],
		});
	};

	useEffect(() => {
		handleUpdateData(dataSection);
	}, [dataSection]);
	return (
		<section className="box_software_sales_2 flex flex-col gap-2 mt-6">
			<div className="box_header_software_sales_2 w-2/3 mx-auto text-center pt-12 flex flex-col">
				<PopoverModel
					content={
						<Input.TextArea
							placeholder="nội dung"
							defaultValue={dataSection.title}
							onChange={(e) =>
								setDataSection({
									...dataSection,
									title: e.target.value,
								})
							}
							rows={4}
							className="w-[300px]"
						/>
					}
					title={
						<span className="text-xs font-normal">Thay đổi</span>
					}
					trigger="click"
					arrow={true}
				>
					<h1 className="!text-[#DDA94B] mb-5 hover:bg-slate-100">
						{dataSection.title || "Tiêu đề"}
					</h1>
				</PopoverModel>

				<PopoverModel
					content={
						<Input.TextArea
							placeholder="nội dung"
							defaultValue={dataSection.sort_description}
							onChange={(e) =>
								setDataSection({
									...dataSection,
									sort_description: e.target.value,
								})
							}
							rows={4}
							className="w-[300px]"
						/>
					}
					title={
						<span className="text-xs font-normal">Thay đổi</span>
					}
					trigger="click"
					arrow={true}
				>
					<span className="!text-[#fff] !font-semibold hover:bg-slate-600">
						{dataSection.sort_description || "Mô tả ngắn"}
					</span>
				</PopoverModel>
				<PopoverModel
					content={
						<Input.TextArea
							placeholder="nội dung"
							defaultValue={dataSection.description}
							onChange={(e) =>
								setDataSection({
									...dataSection,
									description: e.target.value,
								})
							}
							rows={4}
							className="w-[300px]"
						/>
					}
					title={
						<span className="text-xs font-normal">Thay đổi</span>
					}
					trigger="click"
					arrow={true}
				>
					<span className="!text-[#fff] !font-semibold hover:bg-slate-600">
						{dataSection.description || "Mô tả"}
					</span>
				</PopoverModel>
			</div>
			<div className="box_content_software_sales_2 flex justify-start gap-10 py-12 px-2 flex-wrap">
				{dataSection?.listCard?.map((item, index) => (
					<div
						key={index}
						className="relative box_content_software_sales_2_item w-[calc(100%/2-20px)] p-8 flex flex-col justify-between gap-5 rounded-xl shadow-xl"
					>
						<div className="item_top flex flex-col justify-center px-4">
							<PopoverModel
								content={
									<Input.TextArea
										placeholder="nội dung"
										defaultValue={item.title}
										onChange={(e) => {
											const updatedListCard = [
												...dataSection.listCard,
											];
											updatedListCard[index] = {
												...item,
												title: e.target.value,
											};
											setDataSection({
												...dataSection,
												listCard: updatedListCard,
											});
										}}
										rows={4}
										className="w-[300px]"
									/>
								}
								title={
									<span className="text-xs font-normal">
										Thay đổi
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<h1 className="!text-2xl !font-bold pb-3 text-[#09395e] text-center hover:bg-slate-100">
									{item.title || "Tiêu đề"}
								</h1>
							</PopoverModel>
							{item?.listContent?.map(
								(itemContent, indexContent) => (
									<span
										key={indexContent}
										className="!text-[16px] py-1 flex items-start gap-2 text-left hover:bg-slate-100"
									>
										<div className="flex">
											<BsCheck
												color="#00ac3b"
												size={20}
											/>
										</div>
										<PopoverModel
											content={
												<Input.TextArea
													placeholder="nội dung"
													defaultValue={itemContent}
													onChange={(e) => {
														const updatedListContent =
															[
																...item.listContent,
															]; // Sao chép listContent
														updatedListContent[
															indexContent
														] = e.target.value; // Thay đổi phần tử tại index
														const updatedData = {
															...item,
															listContent:
																updatedListContent,
														}; // Sao chép data và cập nhật listContent mới
														const updatedDataSection =
															{
																...dataSection,
															};
														updatedDataSection.listCard[
															index
														] = updatedData; // Cập nhật dữ liệu mới vào listCard

														setDataSection(
															updatedDataSection
														); // Cập nhật dữ liệu Section
													}}
													rows={4}
													className="w-[300px]"
												/>
											}
											title={
												<span className="text-xs font-normal">
													Thay đổi
												</span>
											}
											trigger="click"
											arrow={true}
										>
											{itemContent || "Mô tả"}
										</PopoverModel>
									</span>
								)
							)}
						</div>
						<div
							onClick={(e) => {
								handleChooseImage(item.id);
							}}
							className="item_bottom p-3 w-4/5 mx-auto hover:bg-slate-100"
						>
							<img
								src={
									item.image ||
									"https://api.goodapp.vn/getfile/files/6498312796f391dad61d71d7_1697362088439_advise1.jpg.webp"
								}
								alt="ImageAdvise1"
								className="w-full rounded-xl"
							/>
						</div>
						<UploadImage
							setDataItem={(data) => {
								const updatedListCard = [
									...dataSection.listCard,
								];
								updatedListCard[index] = {
									...item,
									image: data,
								};
								setDataSection({
									...dataSection,
									listCard: updatedListCard,
								});
							}}
							data={item}
							isChooseImage={
								item.id === isChooseImage ? true : false
							}
							handleCancelChooseImage={handleCancelChooseImage}
						/>
						<div className="absolute top-2 right-2">
							<Button
								shape="circle"
								type="text"
								className="flex justify-center items-center"
								size="small"
								title="Xoá"
							>
								<AiOutlineMinus
									onClick={(e) => handleDeleteCard(item.id)}
								/>
							</Button>
						</div>
					</div>
				))}
				{dataSection?.listCard?.length < 2 && (
					<div
						onClick={() => handleAddCard()}
						className="cursor-pointer box_content_software_sales_2_item flex justify-center items-center w-[calc(100%/2-20px)] p-8 gap-5 rounded-xl shadow-xl border border-dotted border-[#09395e] hover:border-white !bg-transparent"
					>
						<IoAdd color="white" size={100} />
					</div>
				)}
			</div>
		</section>
	);
}
