import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { IoSaveOutline } from "react-icons/io5";
import { request } from "../../../utils/request";
import { Button, Image, Modal, Upload } from "antd";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export default function UploadImage({
	data = {},
	setData = () => {},
	isChooseImage,
	handleCancelChooseImage,
	setDataItem = () => {},
}) {
	//upload image
	const [dataImage, setDataImage] = useState([]);

	const handleGetUserFiles = async () => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
					params: {
						t: 1,
						page: 1,
						limit: 500,
					},
				}
			);
			setDataImage(response.data);
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleSaveUserFile = async (file) => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					type: "image",
					link: `https://api.goodapp.vn${file}`,
					exfields: {
						name: "image",
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			handleGetUserFiles();
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		handleGetUserFiles();
	}, []);

	const handleSaveImage = () => {
		if (data.image === "") {
			handleSavePicture();
		}
		handleCancelChooseImage();
	};
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const handleCancel = () => setPreviewOpen(false);
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};
	const handleChange = ({ fileList: newFileList }) =>
		setFileList(newFileList);
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</div>
	);
	const uploadImageApi = async (file) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await request.post(
				`api/uploadfile?json=1&folder=files`,
				formData,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);

			const imageUrl = response.data.fileUrl;
			return imageUrl;
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	const handleSavePicture = async () => {
		try {
			const res = await uploadImageApi(fileList[0].originFileObj);
			await handleSaveUserFile(res);
			setData({
				...data,
				image: `https://api.goodapp.vn${res}`,
			});
			setDataItem(`https://api.goodapp.vn${res}`);
			setDataImage([
				...dataImage,
				{
					link: `https://api.goodapp.vn${res}`,
				},
			]);
		} catch (error) {
			console.log(error);
		}
	};
	const beforeUpload = (file) => {
		setData({
			...data,
			image: "",
		});
		setDataItem("");
		return false;
	};

	return (
		<React.Fragment>
			<Modal
				title="Chọn hình ảnh"
				open={isChooseImage}
				footer={null}
				onCancel={handleCancelChooseImage}
			>
				<p>
					<Upload
						beforeUpload={beforeUpload}
						listType="picture-card"
						fileList={fileList}
						onPreview={handlePreview}
						onChange={handleChange}
					>
						{fileList.length >= 1 ? null : uploadButton}
					</Upload>
				</p>
				<p className="my-3 font-semibold flex justify-start items-center gap-3">
					Thư viện
					<Button
						shape="circle"
						type="text"
						className="flex justify-center items-center"
						size="small"
					>
						<IoSaveOutline onClick={handleSaveImage} />
					</Button>
				</p>
				<div className="flex flex-wrap gap-3">
					{dataImage?.map((item) => (
						<div
							onClick={() => {
								setData({
									...data,
									image: item.link,
								});
								setDataItem(item.link);
							}}
							className={`${
								data?.image === item.link
									? "border-[#09395e]"
									: ""
							} border w-24 h-24 rounded-lg hover:border-[#09395e] cursor-pointer`}
						>
							<Image
								width={96}
								height={96}
								className="rounded-lg object-contain bg-slate-50"
								src={item.link}
								preview={false}
							/>
						</div>
					))}
				</div>
			</Modal>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img
					alt="example"
					style={{
						width: "100%",
					}}
					src={previewImage}
				/>
			</Modal>
		</React.Fragment>
	);
}
