import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { IoAdd } from "react-icons/io5";
import PopoverModel from "../../../components/modal/popoverModel";
import UploadImage from "../../components/uploadImage";
import "../section.scss";

export default function SectionContent2({ data, handleUpdateData }) {
	const [dataSection, setDataSection] = useState(data || {});

	//upload image
	const [isChooseImage, setIsChooseImage] = useState(-1);
	const handleChooseImage = (index) => {
		setIsChooseImage(index);
	};
	const handleCancelChooseImage = () => {
		setIsChooseImage(false);
	};

	// delete itemCard
	const handleDeleteCard = (id) => {
		setDataSection({
			...dataSection,
			listCard: dataSection.listCard.filter((item) => item.id !== id),
		});
	};
	// add itemCard
	const handleAddCard = () => {
		const lastItem = dataSection.listCard[dataSection.listCard.length - 1];
		const newId = lastItem ? lastItem.id + 1 : 1; // Kiểm tra mảng trống rỗng
		const newItem = {
			id: newId,
			// Thêm các trường dữ liệu khác cho phần tử mới ở đây
		};

		setDataSection({
			...dataSection,
			listCard: [...dataSection.listCard, newItem],
		});
	};

	useEffect(() => {
		handleUpdateData(dataSection);
	}, [dataSection]);
	return (
		<section className="section_service_4">
			<div className="box_service_4 flex flex-col items-center justify-center gap-5">
				<div className="flex text-center">
					<PopoverModel
						content={
							<Input.TextArea
								placeholder="nội dung"
								defaultValue={dataSection.title}
								onChange={(e) =>
									setDataSection({
										...dataSection,
										title: e.target.value,
									})
								}
								rows={4}
								className="w-[300px]"
							/>
						}
						title={
							<span className="text-xs font-normal">
								Thay đổi
							</span>
						}
						trigger="click"
						arrow={true}
					>
						<h2 className="!text-3xl !font-semibold text-[#09395e] hover:bg-slate-100 w-4/5 mx-auto">
							{dataSection.title || "Tiêu đề"}
						</h2>
					</PopoverModel>
				</div>
				<div className="border-t-[7px] w-[20vh] my-4"></div>
				<div className="box_service_4_content flex gap-14 w-full justify-start flex-wrap">
					{dataSection?.listCard?.map((item, index) => (
						<div
							key={index}
							className="relative item_service_4_content flex flex-col gap-4 p-10 w-[calc(100%/3-40px)] bg-white rounded-3xl shadow-lg"
						>
							<span
								onClick={(e) => {
									handleChooseImage(item.id);
								}}
								className="flex justify-center items-center hover:bg-slate-100"
							>
								<img
									src={
										item.image ||
										"https://api.goodapp.vn/getfile/files/6498312796f391dad61d71d7_1697377814844_city.svg"
									}
									alt="IconCity"
									className="!max-w-[64px] w-[64px] h-[64px] object-contain"
								/>
							</span>
							<UploadImage
								setDataItem={(data) => {
									const updatedListCard = [
										...dataSection.listCard,
									];
									updatedListCard[index] = {
										...item,
										image: data,
									};
									setDataSection({
										...dataSection,
										listCard: updatedListCard,
									});
								}}
								data={item}
								isChooseImage={
									item.id === isChooseImage ? true : false
								}
								handleCancelChooseImage={
									handleCancelChooseImage
								}
							/>
							<PopoverModel
								content={
									<Input.TextArea
										placeholder="nội dung"
										defaultValue={item.title}
										onChange={(e) => {
											const updatedListCard = [
												...dataSection.listCard,
											];
											updatedListCard[index] = {
												...item,
												title: e.target.value,
											};
											setDataSection({
												...dataSection,
												listCard: updatedListCard,
											});
										}}
										rows={4}
										className="w-[300px]"
									/>
								}
								title={
									<span className="text-xs font-normal">
										Thay đổi
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<h4 className="!text-2xl !font-semibold text-center min-h-[64px] text-[#09395e] hover:bg-slate-100">
									{" "}
									{item.title || "Tiêu đề"}
								</h4>
							</PopoverModel>
							<PopoverModel
								content={
									<Input.TextArea
										placeholder="nội dung"
										defaultValue={item.content}
										onChange={(e) => {
											const updatedListCard = [
												...dataSection.listCard,
											];
											updatedListCard[index] = {
												...item,
												content: e.target.value,
											};
											setDataSection({
												...dataSection,
												listCard: updatedListCard,
											});
										}}
										rows={4}
										className="w-[300px]"
									/>
								}
								title={
									<span className="text-xs font-normal">
										Thay đổi
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<h5 className="!text-[14px] !font-normal text-center min-h-[60px] hover:bg-slate-100">
									{" "}
									{item.content || "Mô tả ngắn"}
								</h5>
							</PopoverModel>
							<div className="absolute top-2 right-2">
								<Button
									shape="circle"
									type="text"
									className="flex justify-center items-center"
									size="small"
									title="Xoá"
								>
									<AiOutlineMinus
										onClick={(e) =>
											handleDeleteCard(item.id)
										}
									/>
								</Button>
							</div>
						</div>
					))}
					{dataSection?.listCard?.length < 3 && (
						<div
							onClick={() => handleAddCard()}
							className="justify-center items-center cursor-pointer item_service_4_content flex flex-col gap-4 p-10 w-[calc(100%/3-40px)] min-h-[320px] bg-slate-200 border border-dotted border-[#09395e] rounded-3xl"
						>
							<IoAdd size={100} />
						</div>
					)}
				</div>
			</div>
		</section>
	);
}
