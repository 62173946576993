import React from "react";
import InputSelect from "../../../../components/inputAnt/inputSelect";
import { Form, Input } from "antd";

export default function InfoProduct({ data }) {
	console.log(data);
	return (
		<div className="flex flex-col gap-3">
			<Form.Item
				name="tk_co"
				label="Tên tài khoản có"
				rules={[{ required: true }]}
			>
				<InputSelect
					api="account"
					valueField="tk"
					labelField="ten_tk"
					q={{ status: true, loai_tk: 1 }}
					t={1}
					prefix={true}
					getLabel={(item) => {
						return `${item?.tk} - ${item?.ten_tk}`;
					}}
					className={"w-56"}
					valueSelect={
						data?.tk_vt !== undefined &&
						data?.ten_tk_vt !== undefined
							? {
									value: data?.tk_vt,
									label: data?.ten_tk_vt,
							  }
							: {
									value: null,
									label: "Chọn tài khoản có",
							  }
					}
				/>
			</Form.Item>
			<Form.Item
				name="dmdvt"
				label="Tên đơn vị tính"
				rules={[{ required: true }]}
			>
				<InputSelect
					api="dmdvt"
					valueField="ma_dvt"
					labelField="ten_dvt"
					q={{ status: true }}
					t={1}
					prefix={true}
					getLabel={(item) => {
						return `${item?.ma_dvt}`;
					}}
					className={"w-56"}
					valueSelect={
						data?.ma_dvt !== undefined &&
						data?.ten_dvt !== undefined
							? {
									value: data?.ma_dvt,
									label: data?.ten_dvt,
							  }
							: { value: null, label: "Chọn đơn vị tính" }
					}
				/>
			</Form.Item>
			<Form.Item
				name="dmnvt"
				label="Nhóm sản phẩm"
				rules={[{ required: true }]}
			>
				<InputSelect
					api="dmnvt"
					valueField="_id"
					labelField="ten_nvt"
					q={{ status: true }}
					t={1}
					prefix={true}
					getLabel={(item) => {
						return `${item?.ten_nvt}`;
					}}
					className={"w-56"}
					valueSelect={
						data?.ma_nvt !== undefined &&
						data?.ten_nvt !== undefined
							? {
									value: data?.ma_nvt,
									label: data?.ten_nvt,
							  }
							: {
									value: null,
									label: "Chọn nhóm sản phẩm",
							  }
					}
				/>
			</Form.Item>
			<Form.Item
				name="ten_vt"
				label="Tên vật tư"
				rules={[{ required: true }]}
			>
				<Input placeholder="Nhập tên vật tư" />
			</Form.Item>
		</div>
	);
}
