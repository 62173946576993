import { Table } from "antd";
import React, { useState } from "react";
import "./tableManager.scss";

const TableManager = ({
	data,
	columns,
	expandable,
	loading,
	scroll,
	rowKey,
	onRow,
	rowSelectionCus,
	pagination = {
		position: "bottomRight",
		defaultPageSize: 20,
	},
	className,
	expandIcon,
}) => {
	const [rowSelection, setRowSelection] = useState({});

	const tableProps = {
		loading: false,
		size: "small",
		rowSelection,
	};
	return (
		<>
			<Table
				{...tableProps}
				pagination={pagination}
				loading={loading}
				columns={columns}
				dataSource={data || []}
				scroll={scroll}
				rowKey={rowKey || "_id"}
				rowSelection={
					rowSelectionCus || {
						onChange: (selectedRowKeys, selectedRows) => {
							console.log(
								`selectedRowKeys: ${selectedRowKeys}`,
								"selectedRows: ",
								selectedRows
							);
							setRowSelection({ selectedRowKeys, selectedRows });
						},
					}
				}
				preserveSelectedRowKeys={true}
				expandable={{
					...expandable,
				}}
				onRow={onRow}
				className={className}
				expandIcon={expandIcon}
			/>
		</>
	);
};
export default TableManager;
