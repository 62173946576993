import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { IoSaveOutline } from "react-icons/io5";
import ItemUploadFile from "../../components/itemUploadFile";

export default function ModalFeedback({
	data,
	handleSave,
	handleEdit,
	handleCancel,
}) {
	const formRef = useRef(null);
	const [form] = Form.useForm();
	const onFinish = async (values) => {
		if (data._id) {
			handleEdit(
				{
					...values,
					picture: dataImageChoose?.picture,
				},
				data._id
			);
		} else {
			handleSave({
				...values,
				picture: dataImageChoose?.picture,
			});
		}
		handleCancel();
	};
	if (data) {
		form.setFieldsValue({
			_id: data?._id,
			title: data?.title,
			description: data?.description,
			content: data?.content,
			picture: data?.picture,
		});
	}

	const [dataImageChoose, setDataImageChoose] = useState({
		picture: data?.picture,
	});

	useEffect(() => {
		setDataImageChoose({
			picture: data?.picture,
		});
	}, [data]);

	return (
		<div className="w-full">
			<Form
				ref={formRef}
				form={form}
				name="control-ref"
				onFinish={onFinish}
				style={{
					marginTop: "30px",
					minWidth: "500px",
					margin: "0 auto",
				}}
				labelCol={{ flex: "35px" }}
				layout="vertical"
			>
				<Row>
					<Col span={6} className="px-2">
						<ItemUploadFile
							setData={setDataImageChoose}
							data={dataImageChoose}
						/>
					</Col>
					<Col span={18} className="px-2">
						<Col span={24}>
							<Form.Item
								name="title"
								label="Tên khách hàng"
								rules={[{ required: true }]}
							>
								<Input placeholder="Nhập tên khách" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="description"
								label="Loại khách hàng"
								rules={[{ required: true }]}
							>
								<Input placeholder="Nhập loại khách" />
							</Form.Item>
						</Col>
					</Col>
					<Col span={24}>
						<Form.Item
							name="content"
							label="Nội dung"
							rules={[{ required: true }]}
						>
							<Input.TextArea
								rows={4}
								placeholder="Nhập nội dung"
							/>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item className="flex m-0">
					<Button
						htmlType="submit"
						className="m-2 !border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
					>
						Lưu
						<IoSaveOutline />
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
