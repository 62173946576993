import DefaultPage from "../DefaultPage/DefaultPage";
import ManagerTemplate from "../Template/ManagerTemplate/ManagerTemplate";
import SectionWeb from "../Template/SectionWeb";
import TemplateWeb from "../Template/TemplateWeb";
import Blogs from "../blogs/blogs";
import ChatBot from "../communicate/chatBot/chatBot";
import Comments from "../communicate/comments/comments";
import Email from "../communicate/email/email";
import CompanyApp from "../companyApp/CompanyApp";
import Category from "../content/category/category";
import Feedback from "../content/feedback/feedback";
import MediaLibrary from "../content/mediaLibrary/mediaLibrary";
import CreatePage from "../content/pages/create/createPage";
import Pages from "../content/pages/pages";
import ManagerPost from "../content/post/manager/managerPost";
import Post from "../content/post/post";
import Products from "../content/products/products";
import ManagerServices from "../content/services/manager/ManagerServices";
import Services from "../content/services/services";
import Tags from "../content/tags/tags";
import HomeCMS from "../home/HomeCMS";
import ManagerMenu from "../managerMenu/managerMenu";
import Settings from "../settings/Settings";
import SignIn from "../signIn/SignIn";
import Participant from "../user/Participant";
import User from "../user/User";
import UserGroup from "../user/UserGroup";

const publicRouter = [{ path: "/sign-in", component: SignIn, layout: null }];
const privateRouter = [
	{ name: "Dashboard", path: "/", component: HomeCMS, layout: "" },
	{
		name: "Thông tin đơn vị, doanh nghiệp",
		path: "/app",
		component: CompanyApp,
		layout: "",
	},
	{
		name: "Posts",
		path: "/posts",
		component: Post,
		layout: "",
	},
	{
		name: "Post Manager",
		path: "/posts/managerPost/:id",
		component: ManagerPost,
		layout: "",
	},
	{
		name: "Pages",
		path: "/pages",
		component: Pages,
		layout: "",
	},
	{
		name: "Pages Create",
		path: "/pages/managerPage/:id",
		component: CreatePage,
		layout: "",
	},
	{
		name: "Media Library",
		path: "/media-library",
		component: MediaLibrary,
		layout: "",
	},
	{
		name: "Customer Feedback",
		path: "/feedback",
		component: Feedback,
		layout: "",
	},
	{
		name: "Services",
		path: "/services",
		component: Services,
		layout: "",
	},
	{
		name: "Services Manager",
		path: "/services/managerServices/:id",
		component: ManagerServices,
		layout: "",
	},
	{
		name: "Products",
		path: "/products",
		component: Products,
		layout: "",
	},
	{
		name: "Tags",
		path: "/tags",
		component: Tags,
		layout: "",
	},
	{
		name: "Categories",
		path: "/categories",
		component: Category,
		layout: "",
	},
	{
		name: "Authors",
		path: "/author",
		component: Tags,
		layout: "",
	},
	{
		name: "Comments",
		path: "/comments",
		component: Comments,
		layout: "",
	},
	{
		name: "Chat Bot",
		path: "/chat-bot",
		component: ChatBot,
		layout: "",
	},
	{
		name: "Email",
		path: "/email",
		component: Email,
		layout: "",
	},
	{ name: "Quản lý Menu", path: "/menu", component: ManagerMenu, layout: "" },
	{ name: "Users", path: "/users", component: User, layout: "" },
	{
		name: "Nhóm người dùng và phân quyền",
		path: "/usergroup",
		component: UserGroup,
		layout: "",
	},
	{
		name: "Người sử dụng chương trình",
		path: "/participant",
		component: Participant,
		layout: "",
	},
	{ name: "Blogs", path: "/blogs", component: Blogs, layout: "" },
	{ name: "Settings", path: "/settings", component: Settings, layout: "" },
	{
		name: "Templates",
		path: "/templates-web",
		component: TemplateWeb,
		layout: "",
	},
	{
		name: "Templates",
		path: "/templates-web/managerTemplate/:id",
		component: ManagerTemplate,
		layout: "",
	},
	{
		name: "Sections",
		path: "/sections-web",
		component: SectionWeb,
		layout: "",
	},
	{
		name: "Sections",
		path: "/:id",
		component: DefaultPage,
		layout: "",
	},
];

export { publicRouter, privateRouter };
