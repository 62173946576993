import { Button, Form, Input } from "antd";
import React, { useRef } from "react";

export default function ModalCategory({ data, handleManegerCategoryNews }) {
	const formRef = useRef(null);
	const [form] = Form.useForm();
	const onFinish = (values) => {
		handleManegerCategoryNews({
			...data,
			...values,
		});
	};
	if (data) {
		form.setFieldsValue({
			_id: data?._id,
			group_name: data?.group_name,
		});
	}
	return (
		<div>
			<Form
				ref={formRef}
				form={form}
				name="control-ref"
				onFinish={onFinish}
				style={{
					margin: "0 auto",
					width: 400,
				}}
				labelCol={{ flex: "35px" }}
				layout="vertical"
			>
				<Form.Item
					name="group_name"
					label="Tên nhóm bài viết"
					rules={[{ required: true }]}
				>
					<Input placeholder="Nhập tên nhóm" />
				</Form.Item>

				<Form.Item className="flex m-0">
					<Button
						type="primary"
						htmlType="submit"
						className="bg-[#326b97] hover:bg-[#09395e] hover:!text-white text-white font-medium !border-none !px-3"
						size="small"
					>
						Lưu
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
