import { PlusOutlined } from "@ant-design/icons";
import {
	Button,
	Checkbox,
	Image,
	Input,
	Modal,
	Radio,
	Select,
	Space,
	Spin,
	Upload,
	message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FiSave } from "react-icons/fi";
import { HiOutlineLogout } from "react-icons/hi";
import { IoSaveOutline } from "react-icons/io5";
import unidecode from "unidecode";
import { request } from "../../../../utils/request";
import EditorDraftWysiwyg from "../../../components/editorDraftWysiwyg/editorDraftWysiwyg";
import PopoverModel from "../../../components/modal/popoverModel";
import "./managerPost.scss";
import { useNavigate } from "react-router-dom";
const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export default function ManagerPost() {
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const [dataPost, setDataPost] = useState({
		title: "Post Name",
		exfields: {
			status_post: "0",
		},
		slug: "post-name",
	});
	const [loading, setLoading] = useState(false);

	const handleGetDataItemPost = async (idPost) => {
		setLoading(true);
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/news/${idPost}`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataPost(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};

	// data loại bài viết
	const [dataCategoryNews, setDataCategoryNews] = useState([]);
	const handleGetData = async (api, q) => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/${api}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
					params: {
						q: { ...q },
					},
				}
			);
			return response?.data;
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetDataAll = async () => {
		try {
			const newDataCategoryNews = await handleGetData("group", {
				status: true,
				group_type: "news",
			});
			setDataCategoryNews(newDataCategoryNews);
		} catch (error) {
			console.log(error);
		}
	};

	const href = window.location.href;
	const idPost = href.split("/")[href.split("/").length - 1];
	useEffect(() => {
		handleGetDataAll();
		handleGetUserFiles();
		if (idPost !== "create") {
			handleGetDataItemPost(idPost);
		} else return;
	}, [idPost]);

	const [dataImage, setDataImage] = useState([]);

	const [isChooseImage, setIsChooseImage] = useState(false);
	const handleChooseImage = () => {
		setIsChooseImage(true);
	};
	const handleCancelChooseImage = () => {
		setIsChooseImage(false);
	};
	const handleSaveImage = () => {
		if (dataPost.image === "") {
			handleSavePicture();
		}
		setIsChooseImage(false);
	};

	//editor content
	const handleGetHtml = (contentState, contentHtml) => {
		setDataPost({
			...dataPost,
			exfields: {
				...dataPost.exfields,
				editorhtml: contentState,
				testContent: document.querySelector('[data-contents="true"]')
					.innerHTML,
			},
			content: contentHtml,
		});
	};

	//Trạng thái hiển thị của post
	const dataStatus = [
		{
			value: "0",
			label: "Công khai",
			description: "Hiển thị tới tất cả.",
		},
		{
			value: "1",
			label: "Riêng tư",
			description: "Chỉ hiển thị cho quản trị viên và biên tập viên",
		},
		{
			value: "2",
			label: "Mật khẩu",
			description:
				"Chỉ những người có mật khẩu mới có thể xem bài viết này.",
		},
	];
	const onChangeStatus = (e) => {
		setDataPost({
			...dataPost,
			exfields: {
				...dataPost.exfields,
				status_post: e.target.value,
			},
		});
	};

	//Url
	const onChangeUrl = (e) => {
		setDataPost({
			...dataPost,
			slug: e.target.value?.trim().replace(/ /g, "-"),
		});
	};

	//Chờ duyệt
	const onChangePending = (e) => {
		setDataPost({
			...dataPost,
			pending: e.target.checked,
		});
	};

	//Chọn tác giả
	const handleChangeAuthor = (value) => {
		setDataPost({
			...dataPost,
			author: value,
		});
	};

	//Gắn tags
	const options = [];
	const handleChangeTags = (value) => {
		setDataPost({
			...dataPost,
			exfields: {
				...dataPost.exfields,
				tags: value,
			},
		});
	};

	//Trạng thái bình luận
	const onChangeComment = (e) => {
		setDataPost({
			...dataPost,
			comment: e.target.checked,
		});
	};

	const handleGetUserFiles = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
					params: {
						t: 1,
						page: 1,
						limit: 500,
					},
				}
			);
			setDataImage(response.data);
			setLoading(false);
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleSaveUserFile = async (file) => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/userfiles`,
				{
					type: "image",
					link: `https://api.goodapp.vn${file}`,
					exfields: {
						name: "image",
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			handleGetUserFiles();
		} catch (error) {
			console.log(error);
		}
	};

	//upload image
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const handleCancel = () => setPreviewOpen(false);
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};
	const handleChange = ({ fileList: newFileList }) =>
		setFileList(newFileList);
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</div>
	);
	const uploadImageApi = async (file) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await request.post(
				`api/uploadfile?json=1&folder=files`,
				formData,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);

			const imageUrl = response.data.fileUrl;
			return imageUrl;
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	const handleSavePicture = async () => {
		try {
			const res = await uploadImageApi(fileList[0].originFileObj);
			await handleSaveUserFile(res);
			setDataPost({
				...dataPost,
				picture: `https://api.goodapp.vn${res}`,
			});
			setDataImage([
				...dataImage,
				{
					link: `https://api.goodapp.vn${res}`,
				},
			]);
		} catch (error) {
			console.log(error);
		}
	};
	const beforeUpload = (file) => {
		// Trả về false để ngăn chặn tải lên tự động khi chọn tệp
		setDataPost({
			...dataPost,
			image: "",
		});
		return false;
	};

	//Category
	// const plainOptions = [
	// 	{
	// 		value: "Chưa phân loại",
	// 		label: "Chưa phân loại",
	// 	},
	// 	{
	// 		value: "Loai 1",
	// 		label: "Loai 1",
	// 	},
	// 	{
	// 		value: "Loai 2",
	// 		label: "Loai 2",
	// 	},
	// 	{
	// 		value: "Loai 3",
	// 		label: "Loai 3",
	// 	},
	// ];
	const plainOptions = dataCategoryNews.map((item) => ({
		value: JSON.stringify({
			item_id: item._id,
			item_name: item.group_name,
		}),
		label: item.group_name,
	}));
	const onChangeCategories = (data) => {
		const newData = JSON.parse(data);
		setDataPost({
			...dataPost,
			category: newData.item_id || "",
			category_name: newData.item_name || "",
		});
	};

	//Tiêu đề
	const onChangeTitle = (e) => {
		setDataPost({
			...dataPost,
			title: e.target.value,
			slug: _.chain(unidecode(e.target.value))
				.trim() // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
				.deburr() // Loại bỏ dấu
				.toLower() // Chuyển thành chữ thường
				.replace(/ /g, "-") // Thay thế khoảng trắng bằng dấu -
				.replace(/\?/g, "") // Loại bỏ ký tự "?"
				.value(), // Lấy giá trị cuối cùng của chuỗi xử lý,
		});
	};

	//Gắn keywords seo
	const optionsKeySEO = [];
	const handleChangeKeySEO = (value) => {
		setDataPost({
			...dataPost,
			exfields: {
				...dataPost.exfields,
				keywords_seo: value,
			},
		});
	};

	const onChangeTitleSEO = (e) => {
		setDataPost({
			...dataPost,
			exfields: {
				...dataPost.exfields,
				title_seo: e.target.value,
			},
		});
	};

	//Mô tả 2
	const onChangeDescription2 = (e) => {
		setDataPost({
			...dataPost,
			exfields: {
				...dataPost.exfields,
				mo_ta_ngan2: e.target.value,
			},
		});
	};

	//Mô tả SEO
	const onChangeDescription = (e) => {
		setDataPost({
			...dataPost,
			exfields: {
				...dataPost.exfields,
				mo_ta_ngan: e.target.value,
			},
		});
	};

	//Manager post
	const handManagerPost = async (link) => {
		if (dataPost?.title === "" || dataPost?.title === undefined) {
			messageApi.open({
				type: "error",
				content: "Nhập Title trước khi lưu!!!",
			});
		} else {
			if (idPost !== "create") {
				setLoading(true);
				try {
					const response = await request.put(
						`api/${localStorage.getItem("id_app")}/news/${idPost}`,
						dataPost,
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
					setLoading(false);
					messageApi.open({
						type: "success",
						content: "Thay đổi thành công",
					});
					link && navigate(link);
				} catch (error) {
					setLoading(false);
					messageApi.open({
						type: "error",
						content: "Thay đổi không thành công",
					});
					console.log(error);
					//throw error;
				}
			} else {
				setLoading(true);
				try {
					const response = await request.post(
						`api/${localStorage.getItem("id_app")}/news`,
						dataPost,
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
					setLoading(false);
					messageApi.open({
						type: "success",
						content: "Tạo thành công",
					});
					link
						? navigate(link)
						: navigate(`/posts/managerPost/${response?.data?._id}`);
				} catch (error) {
					setLoading(false);
					messageApi.open({
						type: "error",
						content: "Tạo thất bại",
					});
					console.log(error);
					//throw error;
				}
			}
		}
	};

	return (
		<Spin spinning={loading}>
			<div className="box_create_post flex h-[calc(100vh-54px)] max-h-[calc(100vh-54px)]">
				{contextHolder}
				<div className="w-[calc(100vw-350px)] h-full bg-slate-50">
					<EditorDraftWysiwyg
						handleGetHtml={handleGetHtml}
						data={dataPost?.exfields?.editorhtml}
					/>
				</div>
				<div className="w-[350px] h-full min-w-[350px]">
					<div className="flex justify-between items-center px-2 py-[6px] border-b mb-4">
						<span className="font-bold text-lg text-[#326b97]">
							Thông tin
						</span>
						<div className="flex justify-end items-center">
							<Button
								title="Save"
								type="submit"
								onClick={() => {
									handManagerPost();
								}}
								className="!border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave />
							</Button>
							<hr className="w-[1px] h-4 bg-slate-600" />
							<Button
								title="Save And Out"
								type="submit"
								onClick={() => {
									handManagerPost("/posts");
								}}
								className="flex items-center gap-2 !border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave /> & <HiOutlineLogout />
							</Button>
						</div>
					</div>
					<div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-4 border-b pb-4 h-[calc(100%-60px)] max-h-[calc(100%-60px)] overflow-auto">
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Title Post
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<Input
										placeholder="title post"
										defaultValue={dataPost.title}
										onChange={onChangeTitle}
									/>
								}
								title={
									<span className="text-xs font-normal">
										Title Page
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs cursor-pointer hover:underline">
									{dataPost?.title}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Hiển thị
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<Radio.Group
										der
										name="radiogroup"
										defaultValue={
											dataPost?.exfields?.status_post
										}
										size="small"
										onChange={onChangeStatus}
									>
										<Space direction="vertical">
											{dataStatus.map((item, index) => {
												return (
													<>
														<Radio
															value={item.value}
														>
															<span className="text-[#326b97] text-[10px]">
																{item.label}
															</span>
														</Radio>
														<span className="text-[10px] pl-6">
															{item.description}
														</span>
													</>
												);
											})}
										</Space>
									</Radio.Group>
								}
								title={
									<span className="text-xs font-normal">
										Hiển thị
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs cursor-pointer hover:underline">
									{
										dataStatus[
											dataPost?.exfields?.status_post
										]?.label
									}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Đăng
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<span className="text-[#09395e] text-xs">
								{moment(dataPost.date_created).format(
									"DD/MM/YYYY"
								)}
							</span>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Url
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<div className="flex flex-col">
										<TextArea
											rows={3}
											placeholder="path page"
											defaultValue={dataPost?.slug
												?.trim()
												.replace(/-/g, " ")}
											onChange={onChangeUrl}
										/>
										<span className="uppercase text-[10px] mt-3 pl-1">
											View post
										</span>
										<a
											href={`${localStorage.getItem(
												localStorage.getItem("id_app")
											)}blogs/${dataPost?.slug}`}
											target="_blank"
											rel="noreferrer"
										>
											<div className="pl-1 text-[#09395e] text-[10px] hover:underline overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer w-[300px]">
												{`${localStorage.getItem(
													localStorage.getItem(
														"id_app"
													)
												)}blogs/` + dataPost?.slug}
											</div>
										</a>
									</div>
								}
								title={
									<span className="text-xs font-normal">
										Đường dẫn tĩnh
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span
									className={
										"text-[#09395e] text-xs hover:underline overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer"
									}
								>
									{`${localStorage.getItem(
										localStorage.getItem("id_app")
									)}blogs/` + dataPost?.slug}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<Checkbox onChange={onChangePending}>
								<span className="text-[#326b97] text-xs">
									Chờ duyệt
								</span>
							</Checkbox>
						</div>
						<div className="flex flex-col gap-3 px-2 w-full">
							{/* <span className="text-[#326b97] text-xs uppercase text-left">
								Tác giả
							</span>
							<Select
								style={{
									width: "300px",
								}}
								onChange={handleChangeAuthor}
								options={[
									{
										value: "admin",
										label: "Admin",
									},
									{
										value: "user1",
										label: "User1",
									},
									{
										value: "user2",
										label: "User2",
									},
								]}
								defaultValue={dataPost?.author}
							/> */}
							<span className="text-[#326b97] text-xs uppercase text-left">
								Tags
							</span>
							<Select
								mode="tags"
								style={{
									width: "100%",
								}}
								onChange={handleChangeTags}
								tokenSeparators={[","]}
								options={options}
								value={dataPost?.exfields?.tags}
							/>
							<span className="text-[#326b97] text-xs uppercase text-left">
								mô tả
							</span>{" "}
							<TextArea
								rows={3}
								onChange={onChangeDescription2}
								value={dataPost?.exfields?.mo_ta_ngan2}
							/>
							<span className="text-[#326b97] text-xs uppercase text-left">
								loại
							</span>
							<Radio.Group
								value={JSON.stringify({
									item_id: dataPost?.category || "",
									item_name: dataPost?.category_name || "",
								})}
								onChange={(e) => {
									onChangeCategories(e.target.value);
								}}
								className="flex flex-col justify-start items-start text-left gap-3 w-full"
							>
								{plainOptions.map((option) => (
									<Radio
										key={option.value}
										value={option.value}
										disabled={option.disabled}
									>
										<span className="text-[#326b97] text-xs">
											{option.label}
										</span>
									</Radio>
								))}{" "}
								<Radio
									value={JSON.stringify({
										item_id: "",
										item_name: "",
									})}
								>
									<span className="text-[#326b97] text-xs">
										Khác
									</span>
								</Radio>
							</Radio.Group>
							<div className="flex flex-col justify-start items-start text-left gap-3 w-full">
								<span className="text-[#326b97] text-xs uppercase text-left">
									Thảo luận
								</span>
								<Checkbox onChange={onChangeComment}>
									<span className="text-[#326b97] text-xs">
										Cho phép bình luận
									</span>
								</Checkbox>
							</div>
							<hr className="w-full h-1 mt-3" />
							<div className="flex flex-col justify-start items-start gap-3">
								<span className="text-[#326b97] text-xs uppercase text-left flex justify-start items-center gap-3">
									Hình ảnh{" "}
									{dataPost?.picture && (
										<Button
											shape="circle"
											type="text"
											className="flex justify-center items-center"
											size="small"
										>
											<CiEdit
												onClick={handleChooseImage}
											/>
										</Button>
									)}
								</span>
								{dataPost?.picture ? (
									<Image
										width={96}
										height={96}
										className="rounded-lg object-cover"
										src={dataPost?.picture}
									/>
								) : (
									<div
										onClick={handleChooseImage}
										className="w-24 h-24 rounded-lg bg-slate-50 border border-dashed flex flex-col justify-center items-center text-slate-500 hover:border-[#09395e] cursor-pointer"
									>
										<PlusOutlined />
										<div
											style={{
												marginTop: 8,
											}}
										>
											Upload
										</div>
									</div>
								)}
								<Modal
									title="Chọn hình ảnh"
									open={isChooseImage}
									footer={null}
									onCancel={handleCancelChooseImage}
								>
									<div className="flex justify-start items-end gap-2 mt-3">
										<Upload
											beforeUpload={beforeUpload}
											listType="picture-card"
											fileList={fileList}
											onPreview={handlePreview}
											onChange={handleChange}
											className="!w-fit"
										>
											{fileList.length >= 1
												? null
												: uploadButton}
										</Upload>
										<Button
											onClick={handleSaveImage}
											className="m-2 !border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
										>
											Lưu hình
											<IoSaveOutline />
										</Button>
									</div>
									<div className="py-3 text-[#326b97] text-base font-semibold text-left">
										Thư viện
									</div>
									<div className="flex flex-wrap gap-3">
										{dataImage?.map((item) => (
											<div
												onClick={() => {
													setDataPost({
														...dataPost,
														picture: item.link,
													});
												}}
												className={`${
													dataPost?.image ===
													item.link
														? "border-[#09395e]"
														: ""
												} border w-24 h-24 rounded-lg hover:border-[#09395e] cursor-pointer`}
											>
												<Image
													width={96}
													height={96}
													className="rounded-lg object-contain bg-slate-50"
													src={item.link}
													preview={false}
												/>
											</div>
										))}
									</div>
								</Modal>
								<Modal
									open={previewOpen}
									title={previewTitle}
									footer={null}
									onCancel={handleCancel}
								>
									<img
										alt="example"
										style={{
											width: "100%",
										}}
										src={previewImage}
									/>
								</Modal>
								<span className="text-[#326b97] text-xs uppercase text-left">
									Keywords SEO
								</span>
								<Select
									mode="tags"
									style={{
										width: "100%",
									}}
									onChange={handleChangeKeySEO}
									tokenSeparators={[","]}
									options={optionsKeySEO}
									value={dataPost?.exfields?.keywords_seo}
								/>
								<span className="text-[#326b97] text-xs uppercase text-left">
									Meta title
								</span>{" "}
								<TextArea
									rows={2}
									onChange={onChangeTitleSEO}
									value={dataPost?.exfields?.title_seo}
								/>
								<span className="text-[#326b97] text-xs uppercase text-left">
									meta description
								</span>{" "}
								<TextArea
									rows={3}
									onChange={onChangeDescription}
									value={dataPost?.exfields?.mo_ta_ngan}
								/>
							</div>
							<hr className="w-full h-1 mt-3" />
							<Button
								type="submit"
								// onClick={handManagerPost}
								className="!border-[#1249c0] hover:!bg-slate-50 hover:!border-[#1249c0] hover:!text-[#1249c0] text-[#3e6bce] font-medium shadow-none flex justify-center items-center gap-1 w-full"
							>
								Lưu bản nháp
							</Button>
							<Button
								type="submit"
								// onClick={handManagerPost}
								className="!border-[#c02612] hover:!bg-slate-50 hover:!border-[#c02612] hover:!text-[#c02612] text-[#c44b3b] font-medium shadow-none flex justify-center items-center gap-1 w-full"
							>
								Di chuyển đến thùng rác
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Spin>
	);
}
