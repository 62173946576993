import { Button, Input, Radio, Space, Spin, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AiOutlineMinus } from "react-icons/ai";
import { IoAdd } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { request } from "../../../utils/request";
import PopoverModel from "../../components/modal/popoverModel";
import TemplatePage from "../TemplatePos/templatePage";
import "./ManagerTemplate.scss";
import PopupModel from "../../components/modal/popupModel";
import ListSection from "../../content/pages/TemplatePage/ListSection/ListSection";
import { FiSave } from "react-icons/fi";
import { HiOutlineLogout } from "react-icons/hi";

export default function CreatePage() {
	const navigate = useNavigate();
	// React state to track order of items
	const [dataTemplatePage, setDataTemplatePage] = useState([]);

	const [messageApi, contextHolder] = message.useMessage();
	const [dataPage, setDataPage] = useState({
		title: "Template Name",
		exfields: {
			data: [],
			status_template: 1,
		},
	});
	const [loading, setLoading] = useState(false);

	const handleGetDataItemTemplate = async (idTemplate) => {
		setLoading(true);
		try {
			const response = await request.get(
				`api/${localStorage.getItem(
					"id_app"
				)}/webtemplate/${idTemplate}`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataPage(response?.data);
			setDataTemplatePage(response?.data?.exfields?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	const href = window.location.href;
	const pathNameTemplate = href.split("/")[href.split("/").length - 1];
	const [searchParams] = useSearchParams();
	const idTemplate = searchParams.get("template");

	useEffect(() => {
		handleGetDataSections();
	}, []);

	useEffect(() => {
		if (pathNameTemplate !== "create") {
			handleGetDataItemTemplate(idTemplate);
		} else handleGetDataItemTemplate(idTemplate);
	}, [pathNameTemplate, idTemplate]);

	useEffect(() => {
		setDataPage({
			...dataPage,
			exfields: {
				...dataPage.exfields,
				data: dataTemplatePage,
			},
		});
	}, [dataTemplatePage]);

	//Title template
	const onChangeTitle = (e) => {
		setDataPage({
			...dataPage,
			title: e.target.value,
		});
	};

	//Trạng thái hiển thị của template
	const dataStatus = [
		{
			value: 0,
			label: "Công khai",
			description: "Hiển thị tới tất cả.",
		},
		{
			value: 1,
			label: "Riêng tư",
			description: "Chỉ hiển thị cho quản trị viên và biên tập viên",
		},
		{
			value: 2,
			label: "Mật khẩu",
			description:
				"Chỉ những người có mật khẩu mới có thể xem bài viết này.",
		},
	];
	const onChangeStatus = (e) => {
		setDataPage({
			...dataPage,
			exfields: {
				...dataPage.exfields,
				status_template: e.target.value,
			},
		});
	};

	//Manager template
	const handleSaveTemplatePage = async (link) => {
		if (dataPage?.title === "" || dataPage?.title === undefined) {
			messageApi.open({
				type: "error",
				content: "Nhập Title trước khi lưu!!!",
			});
		} else {
			if (pathNameTemplate !== "create") {
				setLoading(true);
				try {
					await request.put(
						`api/${localStorage.getItem(
							"id_app"
						)}/webtemplate/${idTemplate}`,
						dataPage,
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
					setLoading(false);
					messageApi.open({
						type: "success",
						content: "Thay đổi thành công",
					});
					link && navigate("/templates-web");
				} catch (error) {
					setLoading(false);
					messageApi.open({
						type: "error",
						content: "Thay đổi thất bại",
					});
					console.log(error);
					//throw error;
				}
			} else {
				setLoading(true);
				try {
					const response = await request.post(
						`api/${localStorage.getItem("id_app")}/webtemplate`,
						dataPage,
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
					setLoading(false);
					messageApi.open({
						type: "success",
						content: "Tạo thành công",
					});
					link
						? navigate("/templates-web")
						: navigate(
								`/templates-web/managerTemplate/edit?template=${response?.data?._id}`
						  );
				} catch (error) {
					setLoading(false);
					messageApi.open({
						type: "error",
						content: "Tạo thất bại",
					});
					console.log(error);
					//throw error;
				}
			}
		}
	};

	// Data Section
	const [dataListSections, setDataListSections] = useState([]);
	const handleGetDataSections = async () => {
		try {
			setLoading(true);
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/websection`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataListSections(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};
	//

	const [openListSection, setOpenListSection] = useState(false);
	const handleCancelListSection = () => {
		setOpenListSection(false);
	};
	const handleAddSectionTemplate = (newDataSection) => {
		setDataTemplatePage([...dataTemplatePage, ...newDataSection]);
		setOpenListSection(false);
		setAddSectionTemplate(false);
	};

	// Function to update list on drop
	const [addSectionTemplate, setAddSectionTemplate] = useState(false);
	const handleDrop = (droppedItem) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...dataTemplatePage];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		// Update State
		console.log(updatedList);
		setDataTemplatePage(updatedList);
	};
	const handleAddSection = () => {
		setOpenListSection(true);
		setAddSectionTemplate(true);
	};
	const handleDeleteSection = (indexToDelete) => {
		const dataPageClone = [...dataTemplatePage];
		const newDataPageClone = dataPageClone.filter(
			(item, index) => index !== indexToDelete
		);
		setDataTemplatePage(newDataPageClone);
	};

	return (
		<Spin spinning={loading}>
			<div className="box_create_template flex h-[calc(100vh-54px)] max-h-[calc(100vh-54px)]">
				{contextHolder}
				<div className="w-[calc(100vw-350px)] h-full bg-slate-50 border">
					<TemplatePage
						dataTemplatePage={dataTemplatePage}
						setDataTemplatePage={setDataTemplatePage}
					/>
				</div>
				<div className="w-[250px] h-full min-w-[350px]">
					<div className="flex justify-between items-center px-2 py-[6px] border-b mb-4">
						<span className="font-bold text-lg text-[#326b97]">
							Thông tin
						</span>
						<div className="flex justify-end items-center">
							<Button
								title="Save"
								type="submit"
								onClick={() => {
									handleSaveTemplatePage();
								}}
								className="!border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave />
							</Button>
							<hr className="w-[1px] h-4 bg-slate-600" />
							<Button
								title="Save And Out"
								type="submit"
								onClick={() => {
									handleSaveTemplatePage("/templates-web");
								}}
								className="flex items-center gap-2 !border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave /> & <HiOutlineLogout />
							</Button>
						</div>
					</div>
					<div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-4 border-b pb-4 h-[calc(100%-60px)] max-h-[calc(100%-60px)] overflow-auto">
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Title Page
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<Input
										placeholder="title page"
										defaultValue={dataPage.title}
										onChange={onChangeTitle}
									/>
								}
								title={
									<span className="text-xs font-normal">
										Title Page
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs cursor-pointer hover:underline">
									{dataPage?.title}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Hiển thị
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<Radio.Group
										der
										name="radiogroup"
										defaultValue={
											dataPage?.exfields?.status_template
										}
										size="small"
										onChange={onChangeStatus}
									>
										<Space direction="vertical">
											{dataStatus.map((item, index) => {
												return (
													<>
														<Radio
															value={item.value}
														>
															<span className="text-[#326b97] text-[10px]">
																{item.label}
															</span>
														</Radio>
														<span className="text-[10px] pl-6">
															{item.description}
														</span>
													</>
												);
											})}
										</Space>
									</Radio.Group>
								}
								title={
									<span className="text-xs font-normal">
										Hiển thị
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs cursor-pointer hover:underline">
									{
										dataStatus[
											dataPage?.exfields?.status_template
										]?.label
									}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Đăng
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<span className="text-[#09395e] text-xs">
								{moment(dataPage.date_created).format(
									"DD/MM/YYYY"
								)}
							</span>
						</div>
						<div className="flex flex-col gap-3 px-2 w-full">
							<hr className="w-full h-1 mt-3" />

							<DragDropContext onDragEnd={handleDrop}>
								<span className="text-[#326b97] text-xs uppercase text-left w-full flex justify-start items-center gap-3">
									Các Section tạo Page{" "}
									<Button
										size="small"
										shape="circle"
										className="flex justify-center items-center"
										onClick={handleAddSection}
									>
										<IoAdd />
									</Button>
								</span>
								<Droppable droppableId="list-container">
									{(provided) => (
										<div
											className="list-container flex flex-col justify-start items-center"
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											{dataTemplatePage.map(
												(item, index) => (
													<Draggable
														key={
															JSON.stringify(
																item
															) + index
														}
														draggableId={
															JSON.stringify(
																item
															) + index
														}
														index={index}
													>
														{(provided) => (
															<div
																className=" px-2 flex justify-between items-center w-full h-[40px] bg-[#5db3f531] rounded-md cursor-pointer border border-white hover:border-[#09395e]"
																ref={
																	provided.innerRef
																}
																{...provided.dragHandleProps}
																{...provided.draggableProps}
															>
																{item.title}
																<Button
																	size="small"
																	shape="circle"
																	className="flex justify-center items-center"
																	onClick={() => {
																		handleDeleteSection(
																			index
																		);
																	}}
																	type="text"
																>
																	<AiOutlineMinus />
																</Button>
															</div>
														)}
													</Draggable>
												)
											)}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>

							<hr className="w-full h-1 mt-3" />
							<Button
								type="submit"
								// onClick={handManagerPage}
								className="!border-[#c02612] hover:!bg-slate-50 hover:!border-[#c02612] hover:!text-[#c02612] text-[#c44b3b] font-medium shadow-none flex justify-center items-center gap-1 w-full"
							>
								Di chuyển đến thùng rác
							</Button>
						</div>
					</div>
				</div>
			</div>
			<PopupModel
				title={"Chọn section tạo Template"}
				style={{
					top: "30px",
				}}
				open={openListSection}
				onCancel={handleCancelListSection}
				width={"70vw"}
				footer={null}
			>
				<ListSection
					type={"template"}
					action={addSectionTemplate}
					dataSections={dataListSections}
					messageApi={messageApi}
					setLoading={setLoading}
					close={handleCancelListSection}
					handleAddSectionTemplate={handleAddSectionTemplate}
				/>
			</PopupModel>
		</Spin>
	);
}
