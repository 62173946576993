import { Button, Input, Popconfirm, Tabs, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GrAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { request } from "../../../utils/request";
import PopupModel from "../../components/modal/popupModel";
import TableManager from "../../components/table/tableManager";
import ModalFeedback from "./Modal/ModalFeedback";
const { Search } = Input;

export default function Feedback() {
	const [openModalFeedback, setOpenModalFeedback] = useState(false);
	const [dataFeedback, setDataFeedback] = useState([]);

	const [dataModal, setDataModal] = useState({});

	const handleGetData = async (api, q) => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/${api}`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "multipart/form-data",
					},
					params: {
						q: { ...q },
					},
				}
			);
			return response?.data;
		} catch (error) {
			console.log(error);
		}
	};
	const handleSaveData = async (data, api = "comment3") => {
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/${api}`,
				data,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			const newData = await handleGetData("comment3", {
				status: true,
			});
			setDataFeedback(newData);
			messageApi.open({
				type: "success",
				content: "Tạo thành công",
			});
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Tạo thất bại",
			});
		}
	};

	const handleEditData = async (data, _id, api = "comment3") => {
		try {
			await request.put(
				`api/${localStorage.getItem("id_app")}/${api}/${_id}`,
				data,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			const newData = await handleGetData("comment3", {
				status: true,
			});
			setDataFeedback(newData);
			messageApi.open({
				type: "success",
				content: "Chỉnh sửa thành công",
			});
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Chỉnh sửa thất bại",
			});
		}
	};
	const handleDeleteData = async (api, id) => {
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/${api}/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			const newData = await handleGetData(api, {
				status: true,
			});
			setDataFeedback(newData);
			messageApi.open({
				type: "success",
				content: "Xóa thành công",
			});
		} catch (error) {
			console.log(error);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
		}
	};

	const handleGetDataAll = async () => {
		try {
			const newDataProduct = await handleGetData("comment3", {
				status: true,
			});
			setDataFeedback(newDataProduct);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		handleGetDataAll();
	}, []);

	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const columns = [
		{
			title: "Khách hàng",
			dataIndex: "title",
			render: (title, record) => (
				<div className="flex flex-col field_title">
					<span>{title}</span>
					<div className="show_group_button">
						<span>
							<div
								onClick={() => {
									setDataModal(record);
									setOpenModalFeedback(true);
								}}
								className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
							>
								Edit
							</div>
							<Popconfirm
								title={`Xoá "${record?.title}"`}
								onConfirm={() => {
									handleDeleteData("comment3", record?._id);
								}}
								onCancel={() => {
									return false;
								}}
								okText="Yes"
								cancelText="No"
							>
								<div className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]">
									Delete
								</div>
							</Popconfirm>
						</span>
					</div>
				</div>
			),
			width: 200,
		},
		{
			title: "Hình ảnh",
			dataIndex: "picture",
			render: (picture) => {
				return (
					<div className="flex justify-center items-center w-14 h-14">
						{picture && (
							<img
								src={picture}
								alt="avatar"
								className="w-14 h-14 rounded-full bg-slate-100 object-cover"
							/>
						)}
					</div>
				);
			},
			width: 100,
		},
		{
			title: "Loại khách hàng",
			dataIndex: "description",
			width: 200,
		},
		{
			title: "Nội dung",
			dataIndex: "content",
			width: 400,
		},
		{
			title: "Ngày tạo",
			dataIndex: "date_created",
			width: 200,
			render: (date_created, record) => {
				return (
					<div className="flex flex-col">
						<span>
							{moment(date_created).format("YYYY-MM-DD HH:mm:ss")}
						</span>
					</div>
				);
			},
		},
	];

	const navigate = useNavigate();
	const onSearch = (value, _e, info) => console.log(info?.source, value);
	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	return (
		<React.Fragment>
			{contextHolder}
			<div className="h-12 flex justify-between items-center pt-4">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Ý kiến khách hàng
				</div>
				<div className="flex justify-end gap-5 items-center box_menu_post">
					<Button
						onClick={() => {
							setOpenModalFeedback(true);
							setDataModal({});
						}}
						className="!border-none !bg-slate-100 hover:!bg-slate-200 hover:!text-[#09395e] text-[#326b97] font-medium shadow-none flex justify-center items-center gap-1 !px-3"
					>
						<GrAdd /> Thêm mới
					</Button>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex justify-between items-center pt-4">
					<div>
						<Search
							placeholder="Từ khoá ..."
							allowClear
							onSearch={onSearch}
							style={{
								width: 300,
							}}
						/>
					</div>
				</div>
				<div className="box_content">
					<TableManager
						data={dataFeedback}
						columns={columns}
						loading={loading}
						scroll={{ x: 800, y: "calc(100vh - 332px)" }}
					/>
				</div>
			</div>
			<PopupModel
				title={"Ý kiến khách hàng"}
				open={openModalFeedback}
				onCancel={() => {
					setOpenModalFeedback(false);
				}}
				footer={null}
				width={"50vw"}
				style={{
					top: "40px",
					"max-height": "90vh",
					"min-width": "600px",
				}}
			>
				<ModalFeedback
					data={dataModal}
					handleSave={handleSaveData}
					handleEdit={handleEditData}
					handleCancel={() => {
						setOpenModalFeedback(false);
					}}
				/>
			</PopupModel>
		</React.Fragment>
	);
}
